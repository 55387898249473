import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
    en: {
        introHeaderVariants: {
            "chair": "See How Fast You Can Hit Your Fit Goals with Chair Workouts",
            "common": "Join Your Dancing Weight Loss Challenge",
            "ceo": "Build a beautiful body with fitness & dance workouts",
            "cfo": "Achieve Your Fitness Goals with Gentle Chair Workouts for Seniors!"
        },
        introPrefixVariants: {
            "common": "Get a new you in 4 weeks",
            "halloween": "Halloween Special Offer",
            "cfo": "Become a new you in just 4 weeks",
            "bf": "🔥Black Friday Special Offer🔥",
            "bfb": "BLACK FRIDAY SPECIAL OFFER"
        },
        introSubtitleLoadingVariants: {
            "common": "Take the FREE quiz to get your personalized dance plan.",
            "ceo": "Take the FREE quiz to get your personalized dance & fitness plan.",

        },
        introSubtitle: "To start please select your main goal:",
        introSubtitleLoading: "Take the FREE quiz to get your personalized dance plan.",
        introLoadingText: "Loading the quiz ",
        introAgreements: "By continuing you agree to",
        introRights: "All rights reserved.",
        continue: "Continue",
        getStarted: "GET STARTED",
        continueMyTrial: "Start my 3 day trial",
        continueTrial1: "Get 3 days for $1.00",
        wait: "Please wait...",
        recommended: "Recommended",
        success: "Successful",
        images: {
            "about-cf-bf": "/images/result/about-result-decoration-image-cf.png",
            "about-cf-bf-webp": "/images/result/about-result-decoration-image-cf.webp",
            "about": "/images/result/about-result-decoration-image.png",
            "about-webp": "/images/result/about-result-decoration-image.webp",
            "about_bf": "/images/result/about-result-decoration-image_bf.png",
            "about_bg-webp": "/images/result/about-result-decoration-image_bf.webp"
        },
        imgs: {
            "black-friday-icon-ret70": "/images/paywall/black-friday-icon-ret70.png",
            "black-friday-icon-ret70Webp": "/images/paywall/black-friday-icon-ret70.webp",
            "black-friday-icon": "/images/paywall/black-friday-icon.png",
            "black-friday-iconWebp": "/images/paywall/black-friday-icon.webp",
            "bf_icon_disc_75": "/images/paywall/bf_icon_disc_75.png",
            "bf_icon_disc_75Webp": "/images/paywall/bf_icon_disc_75.webp",
            "bf_icon_disc_60": "/images/paywall/bf_icon_disc_60.png",
            "bf_icon_disc_60Webp": "/images/paywall/bf_icon_disc_60.webp",
            "bf_icon_trial": "/images/paywall/bf_icon_trial.png",
            "bf_icon_trialWebp": "/images/paywall/bf_icon_trial.webp"
        },
        intro: {
            backgroundImage: null,
            backgroundImageWebp: null,
            header: "Chair Dance Program",
            subheader: "with Top Instructors",
            mainGoal:{
                stayFit: {text: "Stay fit", fontSize: '23px'},
                loseWeight: {text: "Lose weight", fontSize: '23px'},
                burn: {text: "Burn calories", fontSize: '23px'},
                feel: {text: "Feel amazing & confident", fontSize: '23px'},
                dance: {text: "Dance & having fun", fontSize: '23px'},
            },
            reviews: {
            header: "Customer Reviews",
            basedOn: "Based on 1,6k Ratings",
            verified: "Verified Buyer"
            },
            anyDevice:{
            lowImpact: "100% Low Impact Chair Workouts - (Absolutely NO jumping or “crazy” exercises like every other fitness program seems to have). ",
            anyDevice: "Watch from any device. (TV, tablet, laptop, phone, etc.)",
            noFloor: "Absolutely NO Floor Exercises!",
            home: "Exercises Safely from home!",
            program: "A Chair Workout Program that all you have to do is hit play and start seeing results!"
            }
        },
        goal: {
            label: "GOAL",
            labelIntro: "INTRO",
            gender: {
            labels: {
               male: "Male",
               female: "Female",
               other: "Other",
            }
            },
            goalBanner: {
                bannerTitle: "1 million people",
                bannerSubtitle: " have chosen Everdance",
                points: {
                    first: "If you`re limited to stand up exercises",
                    second: "Perfect for seniors",
                    third: "Good for wheelchair people",
                    fourth: "Better for pregnants",
                    fifth: "Better for overweight people",
                }
            },
            mainGoal:{
                title: "What is your main goal?",
                subtitle: "Our plan adapts to all types of goals.",
                labels: {
                    stayFit: "Stay fit",
                    stayHealthyFit: "Stay healthy and fit",
                    loseWeight: "Lose weight",
                    burn: "Burn calories",
                    feel: "Feel amazing and confident",
                    dance: "Dance and having fun",
                    gain: "Gain muscel mass",
                    recover: "Recovering from surgery or an injury",
                    relievePain: "Relieve joint & knee pain",
                    flexibility: "Improve flexibility & balance",
                    healthyLife: "Live the healthy life",
                    mobility: "Improve mobility",
                    activeLimited: "Stay active with limited mobility"
                }
            },
            motivation: {
                title: "What motivates you the most?",
                subtitle: "This will help us find the best program for you.",
                labels: {
                    healthy: "Lead a healthy life",
                    enjoy: "Enjoy yourself",
                    lookBetter: "Look better",
                    reduceStress: "Reduce stress",
                    improveSE: "Improve self-esteem"
                }
            },
            targetZone: {
                title: "Choose your target zone",
                subtitle: "Our workout dance program will focus on that area",
                labels: {
                    arms: "Arms",
                    chest: "Chest",
                    belly: "Belly",
                    hips: "Hips",
                    legs: "Legs",
                }
            },
            currentBody: {
                multicolorHeader: {
                    titleOne: "Choose your",
                    titleTwo: " current",
                    titleThree: " body shape",
                },
                title: "Choose your body shape",
                plump: "Plump",
                skinny: "Skinny"
            },
            targetBody: {
                titleStart: " Choose your",
                titleEnd: " body shape",
                titleDesired: " desired",
                plump: "Plump",
                skinny: "Skinny",
                medium: "Medium",
                curvy: "Curvy"
            },
            result: {
                reviewTitle_default: "Thanks for changing my life",
                reviewTitle_cfo_ng: " It get me up and moving",
                default: "Guys, you're freaking amazing! I never would've thought that dancing could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.",
                cf: "Guys, you're freaking amazing! I never would've thought that chair workouts could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.",
                cfo: "I never would've thought that chair workouts could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.",
                cfo_ng: "Did the beginner chair dance for the first time with coach Akilah and it was GREAT!!! teacher was exceptional! as Selected the chair because my knee started hurting last week for no apparent reason🥺.",
                reviewAuthor: "Ashley Townsend",
                reviewAuthorM: "John Townsend",
                reviewAuthorGA: "Tomas and Emma Townsend",
            }
        },
        about: {
            label: "ABOUT YOU",
            age: {
                title: "How old are you?",
                subtitle: "This will help us made adjustment to your personal plan.",
                age: "AGE",
                hello: "Hello, "
            },
            name: {
                  title: "What is your name?",
                  name: "NAME",
                  hint: "Enter your name"
             },
            height: {
                title: "Your height",
                subtitle: "This will allow us define your body mass index and adapt your workout plan.",
                ft: "FT",
                in: "IN",
                cm: "CM",
                errorGreater: "Value must be greater than or equal to ",
                errorLess: "Value must be less than or equal to "
            },
            currentWeight: {
                title: "Your current weight",
                subtitle: "This will allow us define your body mass index and adapt your workout plan.",
                lbs: "LBS",
                kg: "KG"
            },
            goalWeight: {
                title: "Your goal weight",
                subtitle: "This will allow us define your body mass index and adapt your workout plan.",
                lbs: "LBS",
                kg: "KG"
            },
            errors: {
                numeric: "Numeric value only",
                errorGreater: "Value must be greater than or equal to ",
                errorLess: "Value must be less than or equal to ",
                errorCurrent: "Value must be less than the current weight you entered",
            },
            result: {
                title: "Women in their ",
                titleM: "Men in their ",
                titleO: "People in their ",
                subtitleStart: "Who aim to weight ",
                subtitleEnd: " remember: care & attention should become an integral part of their daily workouts. Don't forget about the health and harmony of your body!",
                subtitleColor: {
                    first: "who want to lose weight,",
                    second: "chair workouts can be a game changer.",
                    second1: "Chair workouts can be a game changer.",
                    start: "No",
                    red: "restrictions",
                    and: "and",
                    blue: "exhausting",
                    end: "workouts.",
                    last: "Lose weight from the comfort of your home.",
                    lastTrain: "Train from the comfort of your home...",
                    bold: "The perfect solution is right here!"
                }
            }
        },
        lifestyle: {
            label: "LIFESTYLE",
            activity: {
                title: "What’s your activity level?",
                subtitle: "Your program will be adjusted to your current lifestyle.",
                levels: {
                    sitting: {
                        title: "Sitting",
                        description: "You get no formal exercise and are not physically active during the day"
                    },
                    sedentary: {
                        title: "Sedentary",
                        description: "You get no formal exercise and are not physically active during the day"
                    },
                    lightly: {
                        title: "Lightly active",
                        description: "You perform lifestyle activities, like taking the dog for a walk or gardening"
                    },
                    moderate: {
                        title: "Moderately active",
                        description: "You participate in cardio exercises for 20 to 60 minutes, 3 to 5 days per week"
                    },
                    high: {
                        title: "Highly active",
                        description: "You exercise for 20 to 60 minutes most days a week"
                    }
                }
            },
            busy: {
                title: "How busy are you on an average day?",
                subtitle: "This will help us find the best program for you.",
                labels: {
                    barelyHaveTime: "I barely have any time for myself",
                    busy: "I’m busy, but I save some time for myself",
                    notBusy: "I’m not too busy",
                    flexible: "My schedule is flexible"
                }
            },
            children: {
                title: "Do you have children?",
                subtitle: "This will help us find the best program for you.",
                labels: {
                    yes:  {
                        text: "Yes",
                        subtext: "Great!!! Dance is a perfect way to improve relationship with your child"
                    },
                    no: "No",
                    grandkids: "Man, I've got grandkids already"
                }
            },
            diet: {
                title: "How would you describe your diet?",
                subtitle: "This will allow us to prepare useful recommendations for you.",
                labels: {
                    healthy: {
                        text: "Healthy",
                        subtext: "I eat a bit of everything and not too much junk food"
                    },
                    not_good: {
                        text: "It could be better",
                        subtext: "I tend to be attracted by greasy food and don't each many vegetables"
                    },
                    poor: {
                        text: "Poor diet",
                        subtext: "I eat mostly greasy food and very few vegetables"
                    }
                }
            },
            result: {
                title: "Sticking to a plan can be hard. Everdance makes it easy.",
                comment: "“Everdance made dancing my daily routine and saved me time on going to workouts. I can now train from home and prioritize self-care.”",
                cfCommentFirst: "“Everdance had great chair exercises.",
                cfCommentLast: "I'm currently trying to loose some weight before my knee replacement surgery and these are great low impact exercises.”",
                commentAuthor: "- Ashley",
                commentAuthorM: "- Mike",
                commentHint: {
                    start: "*Everdance users report positive changes",
                    middle: " after 1-week ",
                    end: "of use."
                }
            }
        },
        nutrition: {
            label: "NUTRITION",
            breakfast: {
                title: "When do you usually have breakfast?",
                sixEight: {
                    text: "Between 6 and 8 am", fontSize: '16px'
                },
                eightTen: {
                    text: "Between 8 and 10 am", fontSize: '16px'
                },
                tenNoon: {
                    text: "Between 10 am and noon", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip breakfast", fontSize: '16px'
                }
            },
            lunch: {
                title: "How about lunch?",
                tenNoon: {
                    text: "Between 10 am and noon", fontSize: '16px'
                },
                noonTwo: {
                    text: "Between noon and 2 pm", fontSize: '16px'
                },
                twoFour: {
                    text: "Between 2 and 4 pm", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip lunch", fontSize: '16px'
                }
            },
            dinner: {
                title: "What time do you have dinner?",
                fourSix: {
                    text: "Between 4 and 6 pm", fontSize: '16px'
                },
                sixEight: {
                    text: "Between 6 and 8 pm", fontSize: '16px'
                },
                eightTen: {
                    text: "Between 8 and 10 pm", fontSize: '16px'
                },
                skip: {
                    text: "I usually skip dinner", fontSize: '16px'
                }
            },
            typeOfDiet: {
                title: "What type of diet do you prefer?",
                withMeat: "WITH MEAT",
                withoutMeat: "WITHOUT MEAT",
                withFish: "WITH FISH",
                withoutAllergens: "WITHOUT ALLERGENS",
                labels: {
                    withMeatTraditional: {
                        text: "Traditional",
                        subtext: "I enjoy everything"
                    },
                    withMeatKeto: {
                        text: "Keto",
                        subtext: "I prefer high-fat low-carb meals"
                    },
                    withMeatPaleo: {
                        text: "Paleo",
                        subtext: "I don’t eat processed foods"
                    },
                    withoutMeatVegetarian: {
                        text: "Vegetarian",
                        subtext: "I avoid meat and fish"
                    },
                    withoutMeatVegan: {
                        text: "Vegan (Plant Diet)",
                        subtext: "I do not eat animal products"
                    },
                    withoutMeatKetoVegan: {
                        text: "Keto Vegan",
                        subtext: "I eat low-carb plant-based meals only"
                    },
                    withFishMediterranean: {
                        text: "Mediterranean",
                        subtext: "I eat plenty of veggies, grains and seafood"
                    },
                    withFishMescatarian: {
                        text: "Pescatarian",
                        subtext: "I avoid meat but enjoy fish"
                    },
                    withoutAllergensLactoseFree: {
                        text: "Lactose Free",
                        subtext: "I do not consume foods with lactose"
                    },
                    withoutAllergensGlutenFree: {
                        text: "Gluten Free",
                        subtext: "I avoid gluten-containing grains"
                    }
                }
            },
            badHabits: {
                title: "Do you have any of the following bad habits?",
                subtitle: "Choose all that apply",
                labels: {
                    emotional: "Emotional or boredom eating",
                    overeating: "Overeating",
                    snacking: "Late-night snacking",
                    skipping: "Skipping meals too often",
                    none: "None of the above"
                }
            },
            craveFoods: {
                title: "What foods do you crave most often?",
                subtitle: "Choose all that apply",
                labels: {
                    sweet: "Sweet treats",
                    salty: "Salty snacks",
                    fast: "Fast food",
                    soda: "Soda",
                    none: "None of the above"
                }
            },
            nutritionResult: {
                title: "Reminder",
                comment: "“80% of your results comes from the food you eat, and only 20% of your results come from your workouts.”",
                note: "*Results are not guaranteed. People who use Everdance can expect to lose 1-2 pounds per week.",
                feedbacks: {
                    genia: {
                        text: "“I’ve lost 9 kg to date and I think Everdance is going to change my life forever.”",
                        name: "- Genia"
                    },
                    sonya: {
                        text: "“Thanks to Everdance I’ve toned my body and changed my habits.”",
                        name: "- Sonya"
                    },
                    zhang: {
                        text: "“I’ve boosted my energy in a new level with good meals and regular dance workouts.”",
                        name: "- Zhang"
                    },
                }
            }
        },
        plan: {
            label: "YOUR PLAN",
            heardAbout: {
                title: "Have you heard about chair dance workouts before?",
                labels: {
                    regularly: "Yes, I practice them regularly",
                    tried: "Yes, I’ve tried",
                    neverTried: "No, I have never tried them"
                }
            },
            chairWorkout: {
                title: "What are chair workouts?",
                comment: {
                    text: "“Performing chair exercises can help the joints and burn calories. They are most beneficial for individuals with mobility problems.”",
                    senior: "Perfect for seniors",
                    subtext: "Perfect for women in",
                    subtextM: "Perfect for men in",
                    subtextO: "Perfect for people in",
                    last: "s"
                }
            },
            benefits: {
                title: "Benefits of chair dance workouts.",
                labels: {
                    weightLoss: "Weight Loss",
                    lowerRisk: "Lower risk of type 2 diabetes",
                    betterHeart: "Better heart health",
                    cancerPrevention: "Cancer prevention",
                    longevity: "Longevity",
                    reducedJointPain: "Reduced Joint Pain and Stiffness",
                    betterBloodCirculation: "Better Blood Circulation"
                }
            },
            whatYouGet: {
                                       title: "What you will get",
                                       labels: {
                                           personalizedPlan: "Personalized Сhair Dancing Plan for weight loss",
                                           feedback: "Individual feedback from professional dance instructors",
                                           fifteenMinutes: "Just 15 minutes per day to see noticable results",
                                           mealPlan: "Personalized meal plan to achieve results even faster",
                                           tips: "Daily tips for a healthy lifestyle",
                                           danceSkill: "Amazing dancing skills on top of sustainable weight loss"
                                       }
            },
            usersLove: {
                title: "Users love our chair workouts",
            },
            experience: {
                title: "What would best describe your dance experience?",
                subtitle: "This will help us to improve your user experience in Everdance.",
                labels: {
                    beginner: {
                        text: "Beginner",
                        subtext: "Just starting"
                    },
                    intermediate: {
                        text: "Intermediate",
                        subtext: "I dance occasionally"
                    },
                    advanced: {
                        text: "Advanced",
                        subtext: "I take dancing classes"
                    }
                }
            },
            styles: {
                title: "Which dance styles do you want to work on?",
                subtitle: "This will help to take into account your preferences in styles when making recommendations.",
                labels: {
                    hiphop: "Hip-Hop",
                    kpop: "K-Pop",
                    dancehall: "Dancehall",
                    latino: "Solo Latino",
                    belly: "Belly Dance",
                    tiktok: "Tik-Tok",
                    vogue: "Vogue",
                    twerk: "Twerk",
                    heels: "Heels",
                    jazzfunk: "Jazz-funk"
                },
                donotknow: "I don't know these styles"
            },
            times: {
                title: {
                    dance: "How many times a week would you like to dance?",
                    workOut: "How many times a week would you like to work out?",
                },
                labels: {
                    0: "1-2",
                    1: "3-4",
                    2: "5 or more"
                }

            },
            session: {
                title:{
                    dance: "How long would you like your dance session to be?",
                    workOut: "How long would you like exercise session to be?",
                },
                labels: {
                    0: "10-15 min",
                    1: "20-30 min",
                    2: "35-45 min",
                    3: "5-10 min",
                    4: "15-30 min"
                }
            },
            danceExercise:{
                title: "Dance vs Regular exercises",
                subtitle: "Average calories burned in 30 min",
                continue: "Continue",
                info: "“If we look at the heart-rate monitor strips from the Zumba fitness session... you burn extra calories compared to a steady-state exercise”"
            },
            chartBanner:{
                title: "Your 4-week Dancing Weight Loss Plan is ready!",
                subtitle: "*based on the information of users who log their progress in the app",
                continue: "Continue",
                infoBox: {
                    percent: "65%",
                    start: " of users who started their ",
                    info: "Dancing Weight Loss Plan with Everdance",
                    cfInfo: "Chair Workout Plan with Everdance",
                    middle: " advanced in their goals within the ",
                    last: "first month*"
                },
            },
            creatingPlan: {
                title: "The most innovative technology in the market.",
                subtitle: "Creating your personalized plan...",
                messages: [
                    {
                        firstTitle: "Personal",
                        secondTitle: " dance plan ",
                        thirdTitle: "according to your goals"
                    },
                    {
                        firstTitle: "Track your progress and",
                        secondTitle: " get achievements",
                        thirdTitle: ""
                    },
                    {
                        firstTitle: "Calories burned",
                        secondTitle: " AI ",
                        thirdTitle: "tracker"
                    }
                ]
            },
            resultProgress: {
                title: "We’ve got you! Let’s tailor your workout program to your fitness profile",
            },
            result: {
                title: "1 million people",
                subtitle: "Trusted by over 1,423,658 users",
                processTitle: "Creating your plan...",
                labels: {
                    25: "Scanning your goals",
                    50: "Analyzing body parameters",
                    75: "Choosing workouts to your needs",
                    100: "Generating your action plan"
                },
                comments: {
                    0: {
                        title: "Amazing!",
                        subtitle: "I’ve lost 9 kg to date and I think Everdance is going to change my life forever.",
                        author: "Genia Johansen",
                    },
                    1: {
                        title: "I love this app",
                        subtitle: "Thanks to Everdance I’ve toned my body and changed my habits",
                        author: "Olivia Emma",
                    },
                    2: {
                        title: "Superb application",
                        subtitle: "I’ve boosted my energy in a new level with good meals and regular dance workouts.",
                        author: "Ummu Yilmaz"
                    },
                    3: {
                        title: "Amazing!",
                        subtitle: "I really enjoyed this workout. It was so much fun and I really got into it and the moves. I can’t believe I’m sweating a bit. It was so much fun. I am going to enjoy working out in my chair.",
                        author: "Genia Johansen",
                    },
                    4: {
                        title: "I love this app",
                        subtitle: "This will help many. Because some people do struggle due to injuries. Or severe obesity. They can do this!!!!...",
                        author: "Olivia Emma",
                    }
                }
            }
        },
        paywall: {
            greenTheme: {
                beforeTitle: "You can reach your goal",
                titles: {
                                ceo: "Fitness & Dance Program"
                                },
                                subtitles:{
                                ceo: "with Roman Podgursky"
                                },
                title: "with Personal Dance Plan",
                titleFitness: "with Personal Fitness Plan",
                cfTitle: "with Personal Chair Dance Plan",
                afterTitle: "with Top Instructors",
                landingTitle: "Chair Dance Program",
                plusBonuses: "+ FREE BONUSES",
                specialPrice: "THIS SPECIAL PRICE IS EXPIRING SOON!",
                landingBonusesTitle: "Grab Chair Dance Plan",
                chartNotice: "*This chart is for illustrative purposes only",
                specialTitle: {
                    title: {
                        bf: "BLACK FRIDAY"
                    },
                    preview: {
                        specialOffer: "SPECIAL OFFER"
                    },
                    upText: {
                        to_50: "UP TO 50% OFF",
                        to_60: "UP TO 60% OFF"
                    }
                },
                mealPlan: {
                    titleOne: "Weight Loss Boost",
                    titleLimited: "Limited Time Offer",
                    titleTwo: "Speed up your results with our",
                    titleThree: "Meal Plan & HIIT Workouts Program",
                    timer: "EXPIRES IN:",
                    stickyTimer: "Free bonus enhancement offer expires in",
                },
                 benefits: {
                     title: "Benefits of chair dance workouts",
                     labels: {
                                         weightLoss: "Weight Loss",
                                         lowerRisk: "Lower risk of type 2 diabetes",
                                         betterHeart: "Better heart health",
                                         cancerPrevention: "Cancer prevention",
                                         longevity: "Longevity",
                                         reducedJointPain: "Reduced Joint Pain and Stiffness",
                                         betterBloodCirculation: "Better Blood Circulation",
                                         posture: "Improve your posture"
                                     }
                },
                whatYouGet: {
                                                       title: "What you will get",
                                                       labels: {
                                                           personalizedPlan: "Personalized Сhair Dancing Plan for weight loss",
                                                           feedback: "Individual feedback from professional dance instructors",
                                                           fifteenMinutes: "Just 15 minutes per day to see noticable results",
                                                           mealPlan: "Personalized meal plan to achieve results even faster",
                                                           tips: "Daily tips for a healthy lifestyle",
                                                           danceSkill: "Amazing dancing skills on top of sustainable weight loss",
                                                           program: "Fitness & dance program to achieve great healthy results",
                                                           workouts: "+350 workouts from professional instructors",
                                                           feedbackRoma: "Individual feedback from Roman",
                                                           results: "Just 25-30 minutes per day to see noticeable results",
                                                           healthyMeal: "Healthy meal plan to achieve results even faster",
                                                           activity: "Daily activity and water trackers"

                                                       }
                            },
                            usersLove: {
                                title: "Users love our chair workouts",
                            },
                cards: {
                    cfTitleOne: "Grab Personal Chair Dance Plan",
                    cfTitleOneS: "Grab Chair Dance Plan",
                    titleOne: "Grab your Personal Dance Plan",
                    titleFitnessOne: "Grab Personal Fitness Plan",
                    titleRoma: "Grab Fitness & Dance Program",
                    titleTwo: "+ Weight Loss Boost",
                    titleFitnessTwo: "+ Limited Time Offer",
                    titleRecovery: "+ Recovery Boost",
                    titleThree: "before it’s gone!",
                    notifyBox: {
                        mainText: "People using Everdance for 12 weeks lose twice as much weight as for 4 weeks*",
                        subText: "*According to Everdance user research, 2024",
                        notifyText: "*You can cancel anytime. All prices are in USD."
                    }
                },
                downloadApps: {
                    title: "1,4+ Million",
                    title_ceo: "150k+ people",
                    subtitle: "users started their weight loss journey with us",
                    subtitle_ceo: "have started their fitness journey with me",
                    subtitleChair: "users started their chair dance journey with us"
                },
                stayOnTop: {
                    titleOne: "Stay on top of your dance journey with ",
                    titleFitness: "Stay on top of your fitness journey with ",
                    titleTwo: "the Everdance app",
                }
            },
            reserved: "Reserved price for:",
            title: {
                start: "Your ",
                personal: "personalized",
                end: " 30-day program is ready"
            },
            products: {
                "1-month plan": "1-month plan",
                "3-month plan": "3-month plan",
                "1-week plan": "1-week plan",
                "6-month plan": "6-month plan",
                "1-WEEK TRIAL": "1-WEEK TRIAL",
                "4-WEEK PLAN": "4-WEEK PLAN",
                "12-WEEK PLAN": "12-WEEK PLAN",
                "24-WEEK PLAN": "24-WEEK PLAN",
                "LIFETIME ACCESS": "LIFETIME ACCESS"
            },
            periods: {
                "3 months": "3 months ",
                "week": "week ",
                "1 month": "1 month ",
                "6 months": "6 months ",
                "quarterly": "quarterly",
                "half a year": "half a year",
                "per month": "per month",
                "weekly": "weekly",
                "1 week trial": "1 week trial ",
                "12 weeks": "12 weeks ",
                "24 weeks": "24 weeks ",
                "4 weeks": "4 weeks ",
                "per 4 week": "per 4 weeks ",
                "per 12 week": "per 12 weeks ",
                "per 24 week": "per 24 weeks ",
                "lifetime": "lifetime access"
            },
            goal: "Goal",
            targetWeight: "Target weight",
            cancelAnytime: "*You can cancel anytime. All prices are in USD.",
            peopleAsk: "People Often Ask",
            love: "Users love our plans",
            get: "What you get",
            features: {
                0: {
                    p1: "Full access to",
                    p2: "350+ dance ",
                    p3: "classes"
                },

                1: {
                    p1: "Personal ",
                    p2:  "dance",
                    p3:  "plan"
                },
                2: "Special prize for our subscribers",
                3: "Live dance classes 2 times a week",
                4: "Daily useful tips&tricks",
                5: {
                    p1: "Feedback from ",
                    p2: " dance instructors"
                },
                6: {
                    p1: "Just 15 minutes per ",
                    p2: "day to see ",
                    p3: " noticeable results"
                },
                7: "Nike Dancers and Lizzo’s Grrrls classes"
            },
            bonus: {
                title: "+ FREE BONUS:",
                mealPlan: "Meal Plan &",
                hiit: "HIIT Workouts Program"
            },
            refund: {
                value: "Our users value us:",
                rate: " refund rate less than 2%*",
                year: "*based on 2022 data",
                days: "30-day ",
                mbg: "money back guarantee"
            },
            discount: {
                previous: "Previous discount: ",
                titleGet: "Get your personal plan with up to",
                titleStart: "Start your personal plan with",
                discount:  " discount"
            },
             trial: {

                            titleStart: "Start your personal plan with",
                            trial:  " 3-day trial",
                            trial3:  " 3-day trial"
                        },
            cardHeader: {
                title: {
                    specialOffer: "SPECIAL OFFER",
                    bfOffer: "BLACK FRIDAY OFFER",
                    nyOffer: "NEW YEAR OFFER"
                },
                imageTitle: {
                    off50: {text: "50% OFF", fontSize: "16px"},
                    off60: {text: "60% OFF", fontSize: "16px"},
                    trial: {text: "3-DAY TRIAL", fontSize: "16px"},
                    off70: {text: "70% OFF", fontSize: "16px"},
                    off75: {text: "75% OFF", fontSize: "16px"},
                    bf: {text: "BLACK FRIDAY", fontSize: "16px"},
                    ny: {text: "NEW YEAR", fontSize: "16px"}
                },
                save: {text: "SAVE ", fontSize: "12px"},
                trial: {text: "3-DAY TRIAL", fontSize: "10px"},
                trial3: {text: "3-DAY TRIAL", fontSize: "13px"},
                sale: {text: "SALE", fontSize: "16px"},
                mostPopular: {text: "MOST POPULAR", fontSize: "12px"},
                oneTimePayment: {text: "ONE TIME PAYMENT", fontSize: "12px"},
                perDay: "per day"
            },

            questions: {
                0: {
                    title: "Can I lose weight by dancing?",
                    subtitle: "You bet! Dancing is a greate cardio workout that not only helps you burn calories, but also helps you feel happier. Lose weight and feel good doing it!"
                },
                1: {
                    title: "How can dancing benefit me?",
                    subtitle: "Dancing can help you lose excess weight, tone muscle, make you more flexible and improve cardiovascular health. Not to mention make you feel happier!"
                },
                2: {
                    title: "Can I get a toned body by dancing?",
                    subtitle: "A Dancer’s Body is a thing for a reason! By dancing regularly, you can impove your muscle tone, get rid of extra pounds, and improve self-esteem."
                },
                3: {
                    title: "Why is dancing a good workout?",
                    subtitle: "Dancing is amazing because it’s suitable for both pros and complete beginners in the sporting world. It requires absolutely nothing to start, and can give you real improvements."
                }
            },
            comments: {
                0: {
                    title: "Amazing!",
                    subtitle: "I never thought that training could be so fun and that I could loose so much weight with an app. I told my mom and now we train together and we love it.",
                    author: "Emma Johansen",
                    date: "21 January"
                },
                1: {
                    title: "I love this app",
                    subtitle: "Guys, much love and respect for what you do! It really helps to loose weight and have fun at the same time! Keep up the good work.",
                    author: "Xavier",
                    date: "5 November"
                },
                2: {
                    title: "Thank you so much",
                    subtitle: "Finally, I found an app that keeps me in shape and doesn’t let me get bored. I’ve been overweight for so long and tried and failed so many times I almost just gave up completely. But I have been genuinely surprised by this app. Lol, I’ve burn so many calories from this app by just dancing, I love it!",
                    author: "Jaiah",
                    date: "11 October"
                }
            },
            feedbacks: {
                0: {
                    name: "Raquel",
                    before: "27 September, 2024",
                    after: "7 February, 2025",
                    weight: "- 9kg (19,8Ib)",
                    feedback: "I've always had a little problem with my belly, it has never looked the way that I have wanted it to. So, after taking advice from my friends, I started doing chair dance workouts with this new app and I was surprised with my results! I lost the weight in my problem areas and my body became more fit and flexible. Plus, I now feel more confident and better overall thanks to dancing positive vibes! Not only does my body look and feel great, but I’ve learned so many new dance moves I can’t wait to show them off!"
                },
                1: {
                    name: "Ashley",
                    before: "15 June, 2024",
                    after: "5 September, 2024",
                    weight: "- 12kg (26Ib)",
                    feedback: "Guys, you're freaking amazing! I never would've thought that chair workouts could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves."
                },
                2: {
                    name: "Trina",
                    before: "22 June, 2024",
                    after: "17 August, 2024",
                    weight: "- 10kg (23Ib)",
                    feedback: "It was a positive journey your app with the chair exercises kicked started my weight loss journey so l could get back to the things l like doing.  I wanted to get back to being outdoors, walking, rollerblading and my favorite is running.  I am 53 at 5'1 with asthma and other underlying condition.  So, with help l achieve my goal...l went from 165 to 142 lbs.  I feel better and now want to spend the money towards outdoor gear and a new pair of pads."
                },
                3: {
                    name: "John",
                    before: "12 May, 2024",
                    after: "8 August, 2024",
                    weight: "- 15kg (33Ib)",
                    feedback: "Thank you so much for your fitness program! I'm 40 years old, and I've been trying to lose weight for a long time, but it was useless. However, thanks to your program and your support, I managed to lose 15 kilograms! It wasn't easy, but the result was worth it. Your advice and recommendations helped me not to give up and keep training. I feel much better and healthier, I have more energy and self-confidence. I will definitely recommend your program to my friends!"
                },
                4: {
                    name: "Sara",
                    before: "7 July, 2024",
                    after: "3 September, 2024",
                    weight: "- 7kg (15Ib)",
                    feedback: "Thanks a lot for your fitness program! I've tried tons of different plans, but I always ended up quitting halfway — the weight just wouldn't budge, and I lost motivation. But your program was a game-changer! I managed to shed 15 pounds and build some solid habits. Now, working out is a regular part of my routine. Your advice and encouragement kept me going, and I feel so much better — more energy, more confidence."
                },
                5: {
                    name: "Mikel",
                    before: "16 December, 2024",
                    after: "5 March, 2025",
                    weight: "- 4kg (9Ib)",
                    feedback: "I've been wanting to get in shape for a long time and finally decided to try your fitness program. The results exceeded all my expectations! During the workouts, I lost 4 kilograms, and this is just the beginning. The program is very well-designed, the exercises are diverse and interesting, and the level of intensity is quite appropriate. I feel cheerful and full of energy. Thank you so much for your work! I will definitely continue exercising!"
                }
            },
            featured: "As featured in:",
            usersMeetGoals: "Our Users Meet Their Goals",
            peopleLikeYou: "People just like you achieved great results using our ",
            peopleLikeYou_ceo: "People just like you achieved great results using my ",
            plan_ceo: "Fitness & Dance Plan",
            plan: "Dancing Weight Loss Plan",
            chairPlan: "Chair Dancing Plan",
            disclaimer: "Disclaimer:",
            key: " Following Exercise and a meal plan is the key in your fitness journey and greatly the results. It’s unusual to lose more than 4 kg per month. Consult a physician first.",
            guarantee: "30-Day Money Back Guarantee",
            results: "We believe that our plan may work for you and you’ll get visible results in 4 weeks!",
            return: "We are even ready to return your money back if you don’t see visible results and can demonstrate that you followed our plan.",
            note: "Please note:",
            subscrRenew: " Subscriptions renew automatically at the end of each period unless you cancel it. If you want to cancel a subscription, write to our Support. Prepayment of total plan cost required. You will need an Android or iOS mobile phone to access the full version of the product. You may want to take a screenshot of this information and save it."
        },
        payment: {
            title: "SELECT PAYMENT METHOD",
            checkout: "Checkout",
            personalized: "Personalized Workout Plan",
            offer: "Introductory offer discount",
            youSave: "You save ",
            total: "Total",
            start: "Start your ",
            trial: "3-day trial",
            trial3: "3-day trial",
            justFor: " just for ",
            then: " then ",
            or:  "or",
            after: "After successful payment, please check your email.",
            afterFirst: {
                after: "After first ",
                per: " per "
            },
            automatically: {
              discount: "We've automatically applied the discount to your ",
              yourTrial: "trial period",
              yourFirst: " first ",
              after: "After the first ",
              renewed: "your subscription will be automatically renewed at the full price of",
              for: "for",
              continuously: "and will then be continuously renewed every",
              until: ""
            },
            cancelAny: "Cancel anytime",
            cardnumber: "Card number",
            mmyy: 'MM/YY',
            cardname: "Cardholder name",
            email: "E-mail",
            perDay: " per day",
            success: "Payment was successful!",
            checkEmail: "Please check your email address specified during the payment.",
            proove: {
             title: "SIGN UP AND SEE RESULTS FOR YOURSELF!",
             guaranteedHealth: "Guaranteed Better Health",
             guaranteedWeightLoss: "Guaranteed Weight Loss",
             guaranteedEnergy: "Guaranteed More Energy",
             guaranteedResults: "Guaranteed Results",
             anyDevice: "Watch On Any Device!",
             results: {
               before: "BEFORE",
               after: "AFTER"
              },
             bonuses: {
             header: "Complimentary Bonuses Included:",
             value: "value",
             mealPlan: "Healthy Meal Plan to achieve results even faster",
             feedback: "Individual feedback from professional instructors",
             workouts: "Access to 350+ workouts",
             tracker: "Online Fitness Tracker",
             vip: "VIP Support",
             boughtSeparately: "Bought separately:",
             getFree: "Get them all today FREE!",
             getBonuses: {
             get: "+Get These ",
             freeBonuses: "FREE BONUSES",
             beforeExpire: " included when you buy before the timer expires!"
             },
             total: {
             thats: "*That’s ",
             worth: "worth of bonuses that you’ll get for",
             free: " FREE ",
             join: "when you join today!"
              }
             }
            }
        },
        registration: {
            steps: {
                0: "Buy\nplan",
                1: "Create\nAccount",
                2: "Live\nClasses",
                3: "Add\noffer",
                4: "Access\nplan",
                stepsWidth: '200px',
                fontSize: '14px'
            },
            liveClasses: {
                title: "Add Live Chair Dance Workouts to your Personal Plan",
                subtitle: "Everdance members who take",
                subtitleBold: "live dance workouts achieve their goals 2x faster",
                continueButton: "Add to my plan"
            },
            addOffer: {
                title: "Exclusive sign-up offer",
                ceoTitle: "Train like a CEO of Everdance app",
                ceoSubtitle: "Get visible results in 4 weeks",
                ceoWorkouts: "12 workouts",
                ceoWarmups: "+2 warm ups",
                ceoAccess: "LIFETIME ACCESS",
                ceoDiscount: "75% OFF",
                continueButton: "Add to my plan",
                skipButton: "Skip this and continue enrollment",
            },
            addOfferList: {
                title: "Exclusive sign-up offer",
                continueButton: "Add to my plan",
                bookAndPlan: "Healthy Tips + Fat-Burning Plan",
                mealPlan: "🎉 Free bonus MEAL PLAN",
                book: "Healthy Tips Book form Akilah",
                plan: "Fat-Burning Home Workout Plan",
                oneTimePayment: "One-time payment",
                uniqueTips: "Unique tips from Akilah to get more value from chair workouts",
                tipsForPlus: "Tips for Plus Size women",
                specialExercise: "Special exercises to speed up your weight loss",
                hiit: "Exclusive HIIT and dance workouts from CEO of Everdance app",
                freeMealPlan: "Free bonus MEAL PLAN"
            },
            skipLink: "Skip",
            title: "Sign up for Everdance",
            subtitle: "to access plan",
            fullname: "Full Name",
            email: "Your email",
            password: "Password",
            confirmPassword: "Confirm Password",
            emailOk: "Current email available for registration",
            emailExists: "Current email already exists",
            emailErr: "Incorrect email value",
            passwordLength: "Password must be at least 6 characters",
            passwordMatch: "Please make sure your passwords match",
            create: "CREATE ACCOUNT",
            withApple: "Continue with Apple",
            withFB: "Continue with Facebook",
            withGmail: "Continue with Google",
            withEmail: "Continue with Email",
            bySigning: "By signing up for Everdance, you agree to the",
            view: "View our",
            created: "Your account was created",
            nextSteps: "Follow this steps to get access to your plan",
            download: "Download the Everdance app",
            toPhone: "to your phone",
            use: "Use ",
            login: "Log in",
            toLogin: " to Log in",
            youShould: "You should tap to Log in and choose the method of registration from the previous step",
            enter: "Choose the method for Log in from the previous step",
        }

    },
    ru: {
        introHeaderVariants: {
            "chair": "See How Fast You Can Hit Your Fit Goals with Chair Workouts",
            "common": "Начни танцевальный челлендж по похудению",
            "ceo": "Начни танцевальный челлендж по фитнесу и похудению",
            "cfo": "Achieve Your Fitness Goals with Gentle Chair Workouts for Seniors!"
        },
        introPrefixVariants: {
            "common": "Новая ты за 4 недели",
            "halloween": "Специальное предложение на Хэллоуин",
            "cfo": "Become a new you in just 4 weeks",
            "bf": "Black Friday Special Offer"
        },
        introSubtitleLoadingVariants: {
            "common": "Пройдите БЕСПЛАТНЫЙ тест, чтобы получить свой индивидуальный план занятий танцами.",
            "ceo": "Пройдите БЕСПЛАТНЫЙ тест, чтобы получить свой индивидуальный план занятий фитнесом и танцами.",
        },
        introSubtitle: "Для старта, выберите свою главную цель:",
        introSubtitleLoading: "Пройдите БЕСПЛАТНЫЙ тест, чтобы получить свой индивидуальный план занятий танцами.",
        introLoadingText: "Загрузка викторины",
        introAgreements: "Продолжая, вы соглашаетесь с:",
        introRights: "Все права защищены.",
        introGoalFit: "Быть в форме",
        introGoalLoseWeight: "Похудеть",
        continue: "Далее",
        continueMyTrial: "Start my 3 day trial",
        continueTrial1: "Get 3 days for $1.00",
        wait: "Подождите...",
        recommended: "Recommended",
        success: "Успешно",
        images: {
            "about-cf-bf": "about-result-decoration-image-cf.png",
            "about-cf-bf-webp": "about-result-decoration-image-cf.webp",
            "about": "about-result-decoration-image_ru.png",
            "about-webp": "about-result-decoration-image_ru.webp",
            "about_bf": "about-result-decoration-image_ru.png",
            "about_bg-webp": "about-result-decoration-image_ru.webp"
        },
        imgs: {
            "black-friday-icon-ret70": "/images/paywall/black-friday-icon-ret70_ru.png",
            "black-friday-icon-ret70Webp": "/images/paywall/black-friday-icon-ret70_ru.webp",
        },
        intro: {
            backgroundImage: "/images/intro/intro_ru.png",
            backgroundImageWebp: "/images/intro/intro_ru.webp",
            mainGoal:{
                stayFit: {text: "Быть в форме", fontSize: '20px'},
                loseWeight: {text: "Похудеть", fontSize: '20px' },
                burn: {text: "Сжечь калории", fontSize: '23px'},
                feel: {text: "Чувствовать себя хорошо и уверенно", fontSize: '23px'},
                dance: {text: "Танцевать и веселиться", fontSize: '23px'},
            }
        },
        goal: {
            label: "ЦЕЛЬ",
            goalBanner: {
                bannerTitle: "1 миллион человек",
                bannerSubtitle: " выбирают Everdance",
                points: {
                    first: "If you`re limited to stand up exercises",
                    second: "Perfect for seniors",
                    third: "Good for wheelchair people",
                    fourth: "Better for pregnants",
                    fifth: "Better for overweight people",
                }
            },
            mainGoal:{
                title: "What is your main goal?",
                subtitle: "Our plan adapts to all types of goals.",
                labels: {
                    stayFit: "Быть в форме",
                    loseWeight: "Похудеть",
                    burn: "Сжечь калории",
                    feel: "Чувствовать себя хорошо и уверенно",
                    dance: "Танцевать и веселиться",
                    recover: "Recovering from surgery or an injury",
                    relievePain: "Relieve joint & knee pain",
                    flexibility: "Improve flexibility",
                    healthyLife: "Live the healthy life",
                    mobility: "Improve mobility",
                    activeLimited: "Stay active with limited mobility"
                }
            },
            motivation: {
                title: "Что мотивирует вас больше всего?",
                subtitle: "",
                labels: {
                    healthy: "Жить здоровой жизнью",
                    enjoy: "Наслаждаться собой",
                    lookBetter: "Выглядеть лучше",
                    reduceStress: "Снизить стресс",
                    improveSE: "Улучшить самооценку",
                }
            },
            targetZone: {
                title: "Выберите проблемные зоны",
                subtitle: "Выберите проблемные зоны",
                labels: {
                    arms: "Руки",
                    chest: "Грудь",
                    belly: "Живот",
                    hips: "Бедра",
                    legs: "Ноги"
                }
            },
            currentBody: {
                multicolorHeader: {
                    titleOne: "Choose your",
                    titleTwo: " current",
                    titleThree: " body shape",
                },
                title: "Выберите свою текущую форму",
                plump: "Полный",
                skinny: "Худой"
            },
            targetBody: {
                titleStart: "Выберите свою ",
                titleEnd: "форму",
                titleDesired: "желаемую ",
                plump: "Полный",
                skinny: "Худой",
                medium: "Подтянутый",
                curvy: "Стройный"
            },
            result: {
                reviewTitle: "Вы изменили мою жизнь",
                reviewText: "Ребята, вы супер! Никогда бы не подумала, что танцы смогут наконец-то помочь мне похудеть без изнурительных тренировок и доставить колоссальное удовольствие от новых движений.",
                cfReviewText: "Guys, you're freaking amazing! I never would've thought that chair workouts could finally help me lose weight without any grueling workouts and giving me immense pleasure from new moves.",
                reviewAuthor: "Татьяна Воронцова"
            }
        },
        about: {
            label: "О ВАС",
            age: {
                title: "Сколько вам лет?",
                subtitle: "Это позволит нам внести изменения для вашего персонального плана.",
                age: "ЛЕТ"
            },

            height: {
                title: "Ваш рост",
                subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
                ft: "фт",
                in: "дм",
                cm: "см"
            },
            currentWeight: {
                title: "Ваш текущий вес",
                subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
                lbs: "фунт",
                kg: "кг"
            },
            goalWeight: {
                title: "Ваш целевой вес",
                subtitle: "Это позволит нам определить индекс вашего тела и адаптировать ваш план",
                lbs: "фунт",
                kg: "кг"
            },
            errors: {
                numeric: "Пожалуйста, вводите только цифры",
                errorGreater: "Значение должно быть больше или равно ",
                errorLess: "Значение должно быть меньше или равно ",
                errorCurrent: "Значение должно быть меньше, чем введенный вами текущий вес",
            },
            result: {
                title: "Девушки в свои ",
                subtitleStart: "Кто стремится к весу  ",
                subtitleEnd: " помните: забота и внимание должны стать неотъемлемой частью ваших ежедневных тренировок. Не забывайте о здоровье и гармонии своего тела!",
                subtitleColor: {
                    first: "who want to lose weight,",
                    second: "chair workouts can be a game changer.",
                    start: "No",
                    red: "restrictions",
                    and: "and",
                    blue: "exhausting",
                    end: "workouts.",
                    last: "Lose weight from the comfort of your home.",
                    lastTrain: "Train from the comfort of your home...",
                    bold: "The perfect solution is right here!"
                }
            }
        },
        lifestyle: {
            label: "ОБРАЗ ЖИЗНИ",
            activity: {
                title: "Насколько вы активны?",
                subtitle: "",
                levels: {
                    sitting: {
                        title: "Sitting",
                        description: "Вы не занимаетесь спортом и не ведете физическую активность в течение дня."
                    },
                    sedentary: {
                        title: "Сидячий",
                        description: "Вы не занимаетесь спортом и не ведете физическую активность в течение дня."
                    },
                    lightly: {
                        title: "Легкая активность",
                        description: " Например, гуляете с собакой или работаете в саду."
                    },
                    moderate: {
                        title: "Средняя активность",
                        description: "Вы занимаетесь кардиоупражнениями от 20 до 60 минут от 3 до 5 дней в неделю."
                    },
                    high: {
                        title: "Высокая активность",
                        description: "Вы тренируетесь от 20 до 60 минут большую часть дней в неделю."
                    }
                }
            },
            busy: {
                title: "Насколько вы заняты в течение дня?",
                subtitle: "",
                labels: {
                    barelyHaveTime: "У меня почти нет времени на себя",
                    busy: "Я занята, но я выделяю немного времени на себя",
                    notBusy: "Я не очень занята",
                    flexible: "Мое время очень гибкое"
                }
            },
            children: {
                title: "У вас есть дети?",
                subtitle: "",
                labels: {
                    yes:  {
                        text: "Да",
                        subtext: "Супер!!! Танец – идеальный способ улучшить отношения с ребенком"
                    },
                    no: "Нет",
                    grandkids: "Man, I've got grandkids already"
                }
            },
            diet: {
                title: "Как вы опишете свое питание?",
                subtitle: "",
                labels: {
                    healthy: {
                        text: "Здоровое",
                        subtext: "Я ем всего понемногу и не слишком много вредной пищи"
                    },
                    not_good: {
                        text: "Может быть лучше",
                        subtext: "Меня, как правило, привлекает жирная пища, и я не ем достаточно овощей."
                    },
                    poor: {
                        text: "Нездоровое питание",
                        subtext: "Я ем в основном жирную пищу и очень мало овощей."
                    }
                }
            },
            result: {
                title: "Придерживаться плана может быть сложно, но с  Everdance это легко.",
                comment: "“Everdance сделал танцы моей повседневной жизнью и сэкономил мне время на походы в зал. Теперь я могу тренироваться дома и уделять себе больше времени.”",
                cfCommentFirst: "“Everdance had great chair exercises.",
                cfCommentLast: "I'm currently trying to loose some weight before my knee replacement surgery and these are great low impact exercises.”",
                commentAuthor: "- Оксана",
                commentHint: {
                    start: "*Пользователи Everdance уже",
                    middle: " через 1 неделю ",
                    end: "замечают положительные изменения. "
                }
            }
        },
        nutrition: {
            label: "ПИТАНИЕ",
            breakfast: {
                title: "Во сколько вы обычно завтракаете?",
                sixEight: {
                    text: "Между 6 и 8 утра", fontSize: '16px'
                },
                eightTen: {
                    text: "Между 8 и 10 утра", fontSize: '16px'
                },
                tenNoon: {
                    text: "Между 10 утра и полуднем", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю завтрак", fontSize: '16px'
                }
            },
            lunch: {
                title: "Что насчет обеда?",
                tenNoon: {
                    text: "Между 10 утра и полуднем", fontSize: '16px'
                },
                noonTwo: {
                    text: "Между полуднем и 2 часами дня", fontSize: '16px'
                },
                twoFour: {
                    text: "Между 2 и 4 часами дня", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю обед", fontSize: '16px'
                }
            },
            dinner: {
                title: "В какое время вы ужинаете?",
                fourSix: {
                    text: "Между 4 и 6 вечера", fontSize: '16px'
                },
                sixEight: {
                    text: "Между 6 и 8 вечера", fontSize: '16px'
                },
                eightTen: {
                    text: "Между 8 и 10 вечера", fontSize: '16px'
                },
                skip: {
                    text: "Я обычно пропускаю ужин", fontSize: '16px'
                }
            },
            typeOfDiet: {
                title: "Какой тип питания вы предпочитаете?",
                withMeat: "С МЯСОМ",
                withoutMeat: "БЕЗ МЯСА",
                withFish: "С РЫБОЙ",
                withoutAllergens: "БЕЗ АЛЛЕРГЕНОВ",
                labels: {
                    withMeatTraditional: {
                        text: "Традиционный",
                        subtext: "Мне нравится все"
                    },
                    withMeatKeto: {
                        text: "Кето",
                        subtext: "Я предпочитаю высокожирную низкоуглеводную пищу"
                    },
                    withMeatPaleo: {
                        text: "Палео",
                        subtext: "Я не употребляю обработанную пищу"
                    },
                    withoutMeatVegetarian: {
                        text: "Вегетарианский",
                        subtext: "Я не употребляю мясо и рыбу"
                    },
                    withoutMeatVegan: {
                        text: "Веган (Растительная диета)",
                        subtext: "Я не употребляю животных продуктов"
                    },
                    withoutMeatKetoVegan: {
                        text: "Кето Веган",
                        subtext: "Я предпочитаю высокожирную низкоуглеводную пищу только растительного происхождения"
                    },
                    withFishMediterranean: {
                        text: "Средиземноморский",
                        subtext: "Я употребляю много овощей, злаков и морепродуктов"
                    },
                    withFishMescatarian: {
                        text: "Пескатарианский",
                        subtext: "Я не употребляю мясо, но добавляю в рацион рыбу"
                    },
                    withoutAllergensLactoseFree: {
                        text: "Безлактозный",
                        subtext: "Я не употребляю в пищу продукты с лактозой"
                    },
                    withoutAllergensGlutenFree: {
                        text: "Безглютеновый",
                        subtext: "Я избегаю продуктов, содержащих глютен"
                    }
                }
            },
            badHabits: {
                title: "У вас есть какие-нибудь вредные пищевые привычки?",
                subtitle: "Выберите все подходящие варианты",
                labels: {
                    emotional: "Эмоциональное заедание",
                    overeating: "Переедание",
                    snacking: "Ночные перекусы",
                    skipping: "Частые пропуски приемов пищи",
                    none: "Ничего из перечисленного"
                }
            },
            craveFoods: {
                title: "Какой пищи вам обычно больше всего хочется?",
                subtitle: "Выберите все подходящие варианты",
                labels: {
                    sweet: "Сладости",
                    salty: "Соленые перекусы",
                    fast: "Фаст-фуды",
                    soda: "Газированные напитки",
                    none: "Ничего из перечисленного"
                }
            },
            nutritionResult: {
                title: "Напоминание",
                comment: "“80% от вашего результата приходит от еды, которую вы едите, и только 20%  вашего результата появляются от тренировок.”",
                note: "*Результаты не гарантированы. Люди, использующие Everdance, могут ожидать потерю веса до 0.5-1 кг за неделю.",
                feedbacks: {
                    genia: {
                        text: "“Я скинула 9 кг к настоящему времени и  я думаю, что Everdance - это то, что изменит мою жизнь навсегда.”",
                        name: "- Евгения"
                    },
                    sonya: {
                        text: "“Спасибо Everdance! Я подтянула свое тело и изменила к лучшему свои привычки.”",
                        name: "- София"
                    },
                    zhang: {
                        text: "“Моя энергия значительно увеличилась благодаря хорошему питанию и регулярным упражнениям.”",
                        name: "- Чжанг"
                    },
                }
            }
        },
        plan: {
            label: "ТВОЙ ПЛАН",
            heardAbout: {
                title: "Have you heard about chair dance workouts before?",
                labels: {
                    regularly: "Yes, I practice them regularly",
                    tried: "Yes, I’ve tried",
                    neverTried: "No, I have never tried them"
                }
            },
            chairWorkout: {
                title: "What are chair workouts?",
                comment: {
                    text: "“Performing chair exercises can help the joints and burn calories. They are most beneficial for individuals with mobility problems.”",
                    senior: "Perfect for seniors",
                    subtext: "Perfect for women in",
                    empty: "",
                    last: "s"
                }
            },
            benefits: {
                title: "Benefits of chair dance workouts.",
                labels: {
                    weightLoss: "Weight Loss",
                    lowerRisk: "Lower risk of type 2 diabetes",
                    betterHeart: "Better heart health",
                    cancerPrevention: "Cancer prevention",
                    longevity: "Longevity",
                    reducedJointPain: "Reduced Joint Pain and Stiffness",
                    betterBloodCirculation: "Better Blood Circulation",
                    posture: "Improve your posture",
                }
            },
           whatYouGet: {
                           title: "What you will get",
                           labels: {
                               personalizedPlan: "Personalized Сhair Dancing Plan for weight loss",
                               feedback: "Individual feedback from professional dance instructors",
                               fifteenMinutes: "Just 15 minutes per day to see noticable results",
                               mealPlan: "Personalized meal plan to achieve results even faster",
                               tips: "Daily tips for a healthy lifestyle",
                               danceSkill: "Amazing dancing skills on top of sustainable weight loss"
                           }
           },
            usersLove: {
                title: "Users love our chair workouts",
            },
            experience: {
                title: "Как бы вы описали ваш танцевальный опыт?",
                subtitle: "",
                labels: {
                    beginner: {
                        text: "Начинающий",
                        subtext: "Только пробую себя в танцах"
                    },
                    intermediate: {
                        text: "Средний",
                        subtext: "Я танцую иногда"
                    },
                    advanced: {
                        text: "Продвинутый",
                        subtext: "Я обучаюсь танцам"
                    }
                }
            },
            styles: {
                title: "С какими стилями вы бы хотели поработать?",
                subtitle: "",
                labels: {
                    hiphop: "Хип-Хоп",
                    kpop: "К-Поп",
                    dancehall: "Дансхолл",
                    latino: "Соло Латино",
                    belly: "Танец живота",
                    tiktok: "Tik-Tok",
                    vogue: "Вог",
                    twerk: "Тверк",
                    heels: "Хиллз",
                    jazzfunk: "Джаз-фанк"
                },
                donotknow: "Я не знаю этих стилей"
            },
            times: {
                title: {
                    dance: "Сколько раз в неделю вы хотели бы заниматься танцами?",
                    workOut: "How many times a week would you like to work out?",
                },
                labels: {
                    0: "1-2",
                    1: "3-4",
                    2: "5 или более"
                }

            },
            session: {
                title:{
                    dance: "Как долго вам было бы удобно тренироваться в течение одной сессии?",
                    workOut: "How long would you like exercise session to be?",
                },
                labels: {
                    0: "10-15 минут",
                    1: "20-30 минут",
                    2: "35-45 минут"
                }
            },
            danceExercise:{
                title: "Танцы vs Обычные упражнения",
                subtitle: "Средний расход калорий за 30 мин",
                continue: "Продолжить",
                info: "“Если мы посмотрим на линии сердечного ритма с Зумба фитнес сессии... вы сжигаете больше калорий по сравнению с обычными упражнениями”"
            },
            chartBanner:{
                title: "Your 4-week Dancing Weight Loss Plan is ready!",
                subtitle: "*based on the information of users who log their progress in the app",
                continue: "Continue",
                infoBox: {
                    percent: "65%",
                    start: " of users who started their ",
                    info: "Dancing Weight Loss Plan with Everdance",
                    cfInfo: "Chair Workout Plan with Everdance",
                    middle: " advanced in their goals within the ",
                    last: "first month*"
                },
            },
            creatingPlan: {
                title: "The most innovative technology in the market.",
                subtitle: "Creating your personalized plan...",
                messages: [
                    {
                        firstTitle: "Personal",
                        secondTitle: " dance plan ",
                        thirdTitle: "according to your goals"
                    },
                    {
                        firstTitle: "Track your progress and",
                        secondTitle: " get achievements",
                        thirdTitle: ""
                    },
                    {
                        firstTitle: "Calories burned",
                        secondTitle: " AI ",
                        thirdTitle: "tracker"
                    }
                ]
            },
            resultProgress: {
                title: "We’ve got you! Let’s tailor your workout program to your fitness profile",
            },
            result: {
                title: "1 миллион человек",
                subtitle: "выбирают Everdance",
                processTitle: "Создание плана...",
                labels: {
                    25: "Определение вашего уровня",
                    50: "Анализ ваших целей",
                    75: "Адаптация плана под ваш образ жизни",
                    100: "Ваш план готов!"
                },
                comments: {
                    0: {
                        title: "Удивительно!",
                        subtitle: "Я никогда не думала, что тренировки могут быть такими увлекательными и что с помощью приложения я смогу так сильно похудеть. Я рассказала маме, и теперь мы тренируемся вместе, и нам это нравится.",
                        author: "Ольга Никитенко",
                    },
                    1: {
                        title: "Я люблю это приложение",
                        subtitle: "Ребята, с большой любовью и уважением к тому, что вы делаете! Это действительно помогает похудеть и одновременно весело провести время! Продолжайте хорошую работу.",
                        author: "Тамара Серова",
                    },
                    2: {
                        title: "Классное приложение",
                        subtitle: "Великолепное приложение, очень мотивирующее, курсы на любой вкус! Как здорово иметь такое средство, которое помогает похудеть, поднять настроение и весело провести время!",
                        author: "Юлия Кравцова"
                    },
                    3: {
                        title: "Amazing!",
                        subtitle: "I really enjoyed this workout. It was so much fun and I really got into it and the moves. I can’t believe I’m sweating a bit. It was so much fun. I am going to enjoy working out in my chair.",
                        author: "Genia Johansen",
                    },
                    4: {
                        title: "I love this app",
                        subtitle: "This will help many. Because some people do struggle due to injuries. Or severe obesity. They can do this!!!!...",
                        author: "Olivia Emma",
                    }
                }
            }
        },

        paywall: {
            greenTheme: {
                titles: {
                ceo: "Fitness & Dance Program"
                },
                subtitles:{
                ceo: "with Roman Podgursky"
                },
                beforeTitle: "You can reach your goal",
                landingTitle: "Chair Dance Program",
                cfTitle: "with Personal Chair Dance Plan",
                title: "with Personal Dance Plan",
                afterTitle: "with Top Instructors",
                specialTitle: {
                    title: {
                        bf: "BLACK FRIDAY"
                    },
                    preview: {
                        specialOffer: "SPECIAL OFFER"
                    },
                    upText: {
                        to_50: "UP TO 50% OFF"
                    }
                },
                chartNotice: "*This chart is for illustrative purposes only",
                mealPlan: {
                    titleOne: "Weight Loss Boost",
                    titleLimited: "Limited Time Offer",
                    titleTwo: "Speed up your results with our",
                    titleThree: "Meal Plan & HIIT Workouts Program",
                    timer: "EXPIRES IN:",
                    stickyTimer: "Free bonus enhancement offer expires in",
                },
                cards: {
                    cfTitleOne: "Grab Personal Chair Dance Plan",
                    titleOne: "Grab your Personal Dance Plan",
                    titleFitnessOne: "Grab Personal Fitness Plan",
                    titleRoma: "Grab Fitness & Dance Program",
                    titleTwo: "+ Weight Loss Boost",
                    titleFitnessTwo: "+ Limited Time Offer",
                    titleThree: "before it’s gone!",
                    notifyBox: {
                        mainText: "People using Everdance for 12 weeks lose twice as much weight as for 4 weeks*",
                        subText: "*According to Everdance user research, 2024",
                        notifyText: "*You can cancel anytime. All prices are in USD."
                    }
                },
                benefits: {
                   title: "Benefits of chair dance workouts"
                },
                downloadApps: {
                    title: "1,4+ Million",
                    title_ceo: "150k+ people",
                    subtitle: "users started their weight loss journey with us",
                    subtitle_ceo: "have started their fitness journey with me",
                    subtitleChair: "users started their chair dance journey with us"
                },
                stayOnTop: {
                    titleOne: "Stay on top of your dance journey with ",
                    titleTwo: "the Everdance app",
                }
            },
            reserved: "Скидка исчезнет через:",
            title: {
                start: "Ваш ",
                personal: "персональный",
                end: " 30-дневный план готов"
            },
            goal: "Цель",
            targetWeight: "Целевой вес",
            cancelAnytime: "*Вы можете отменить подписку в любой момент. Все цены указаны в долларах.",
            peopleAsk: "Люди Обычно Спрашивают:",
            love: "Пользователи любят нас",
            get: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
            products: {
                "1-month plan": "1 месяц",
                "3-month plan": "3 месяца",
                "1-week plan": "1 неделя",
                "6-month plan": "6 месяцев",
            },
            periods: {
                "3 months": "3 месяца ",
                "week": "1 неделю ",
                "1 month": "1 месяц ",
                "6 months": "6 месяцев ",
                "quarterly": "раз в 3 месяца",
                "half a year": "раз в полгода",
                "per month": "ежемесячно",
                "weekly": "еженедельно"
            },
            features: {
                0: {
                    p1: "Доступ к +350",
                    p2:  "танцевальным",
                    p3: "тренировкам"
                },
                1: {
                    p1: "Персональный",
                    p2: "танцевальный",
                    p3: "план"},
                2: "Special prize for our subscribers",
                3: "Live dance classes 2 times a week",
                4: "Daily useful tips&tricks",
                5: {
                    p1: "Поддержка",
                    p2: "тренеров"
                },
                6: {
                    p1: "Всего 15 минут в",
                    p2: "день для отличного",
                    p3: "результата"
                },
                7: "Nike Dancers and Lizzo’s Grrrls classes"
            },
            bonus: {
                title: "+ БЕСПЛАТНЫЙ БОНУС:",
                mealPlan: "План Питания и ",
                hiit: "Табата программа"
            },
            refund: {
                value: "Наши пользователи ценят нас:",
                rate: "процент возврата менее 2%*",
                year: "*по данным 2022 года",
                days: "30 дней ",
                mbg: "гарантия возврата"
            },
            discount: {
                previous: "Предыдущая скидка: ",
                titleGet: "Получи свой персональный план с",
                titleStart: "Начни свой персональный план с",
                discount:  " скидкой"
            },
            trial: {

                                        titleStart: "Start your personal plan with",
                                        trial:  " 3-day trial"
                                    },
            cardHeader: {
                title: {
                    specialOffer: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
                    bfOffer: "ЧЕРНАЯ ПЯТНИЦА",
                    nyOffer: "НОВОГОДНЕЕ ПРЕДЛОЖЕНИЕ"
                },
                imageTitle: {
                    off50: {text: "50% СКИДКА", fontSize: "11px"},
                    off60: {text: "60% СКИДКА", fontSize: "11px"},
                    trial: {text: "3-ДНЕВНЫЙ ТРИАЛ", fontSize: "16px"},
                    off70: {text: "70% СКИДКА", fontSize: "11px"},
                    off75: {text: "75% СКИДКА", fontSize: "11px"},
                    bf: {text: "ЧЕРНАЯ ПЯТНИЦА", fontSize: "11px"},
                    ny: {text: "НОВОГОДНЕЕ ПРЕДЛОЖЕНИЕ", fontSize: "11px"}
                },
                save: {text: "СКИДКА ", fontSize: "10px"},
                trial: {text: "3-ДНЕВНЫЙ ТРИАЛ ", fontSize: "10px"},
                sale: {text: "СКИДКА", fontSize: "11px"},
                mostPopular: {text: "САМЫЙ ПОПУЛЯРНЫЙ", fontSize: "10px"},
                perDay: "в день"
            },

            questions: {
                0: {
                    title: "Можно ли похудеть с помощью танцев?",
                    subtitle: "Танцы — это отличная кардио-тренировка, которая не только помогает сжигать калории, но и помогает чувствовать себя счастливее. Похудейте и почувствуйте себя хорошо, делая это!"
                },
                1: {
                    title: "Чем мне могут помочь танцы?",
                    subtitle: "Танцы помогут вам сбросить лишний вес, привести мышцы в тонус, сделать вас более гибкими и улучшить здоровье сердечно-сосудистой системы. Не говоря уже о том, чтобы вы почувствовали себя счастливее!"
                },
                2: {
                    title: "Можно ли получить подтянутое тело с помощью танцев?",
                    subtitle: "Регулярно занимаясь танцами, можно повысить тонус мышц, избавиться от лишних килограммов и повысить самооценку."
                },
                3: {
                    title: "Почему танцы — это хорошая тренировка?",
                    subtitle: "Танцы подходят как профессионалам, так и новичкам в мире спорта. Для начала не требуется специальное оборудование, и они  дадут вам реальные улучшения."
                }
            },
            comments: {
                0: {
                    title: "Удивительно!",
                    subtitle: "Я никогда не думала, что тренировки могут быть такими увлекательными и что с помощью приложения я смогу так сильно похудеть. Я рассказала маме, и теперь мы тренируемся вместе, и нам это нравится.",
                    author: "Ольга Никитенко",
                    date: "21 Января"
                },
                1: {
                    title: "Я люблю это приложение",
                    subtitle: "Ребята, с большой любовью и уважением к тому, что вы делаете! Это действительно помогает похудеть и одновременно весело провести время! Продолжайте хорошую работу.",
                    author: "Тамара Серова",
                    date: "5 Ноября"
                },
                2: {
                    title: "Классное приложение",
                    subtitle: "Великолепное приложение, очень мотивирующее, курсы на любой вкус! Как здорово иметь такое средство, которое помогает похудеть, поднять настроение и весело провести время!",
                    author: "Юлия Кравцова",
                    date: "11 Октября"
                }
            },
            feedbacks: {
                0: {
                    name: "Ирина",
                    before: "27 Января, 2023",
                    after: "22 Марта, 2023",
                    weight: "- 4кг",
                    feedback: "У меня всегда были небольшие проблемы с животом, он никогда не выглядел так, как мне хотелось. Итак, по совету друзей, я начала заниматься танцами с помощью этого нового приложения и была удивлена своими результатами! Я похудела в проблемных зонах, мое тело стало более подтянутым и гибким. Кроме того, теперь я чувствую себя увереннее и лучше в целом благодаря танцевальным позитивным эмоциям! "
                },
                1: {
                    name: "Анна",
                    before: "1 Mая, 2023",
                    after: "15 Сентября, 2023",
                    weight: "- 12 кг",
                    feedback: "Я очень рада, что могу так легко и с удовольствием вернуться к своей форме до рождения ребенка благодаря танцевальным тренировкам. Мамы поймут, как сложно найти время для ухода за собой, когда у вас кричащий новорожденный и каждый день ощущается как День сурка. Но последние несколько месяцев я танцевала дома, и это было такое удовольствие! Изучаю новые танцевальные движения, вижу, как мое тело становится более гибким и подтянутым, и наблюдаю, как уменьшается мой живот. И самое главное, у меня улучшилось настроение и я заново открыл для себя, на что способно мое тело!"
                },
                2: {
                    name: "Светлана",
                    before: "5 Апреля, 2023",
                    after: "27 Июля, 2023",
                    weight: "- 7 кг",
                    feedback: "У меня всегда была стройная фигура, и я ела все, что хотела, не набирая при этом вес. Но это заставило меня слишком расслабиться в отношении своих привычек. Со временем я начала набирать вес, даже не осознавая этого. Так было до тех пор, пока мой врач не шокировал меня, сказав, что у меня разовьется диабет, если я не изменю свои привычки и не добавлю больше физической активности. Я нашла это приложение и начала тренироваться по плану. Через пару недель стала замечать, как возвращается моя привычная энергия, мое тело стало более подтянутым, и я стала чаще улыбаться."
                }
            },
            featured: "О нас пишут в:",
            usersMeetGoals: "Our Users Meet Their Goals",
            peopleLikeYou: "Люди, которые уже достигли своих целей с ",
            plan: "Танцевальным Планом Похудения",
            disclaimer: "Отказ от ответственности:",
            key: " соблюдение плана тренировок и питания — это ключ к вашему фитнес-результату. Перед стартом проконсультируйтесь с врачом.",
            guarantee: "30 Дней Гарантия Возврата",
            results: "Мы верим, что наш план подойдет вам и вы получите видимые результаты уже через 4 недели! ",
            return: "Мы даже готовы вернуть вам деньги, если вы не увидите видимых результатов и сможете продемонстрировать, что следовали нашему плану.",
            note: "Обратите внимание:",
            subscrRenew: " подписки продлеваются автоматически в конце каждого периода, если вы их не отмените. Если вы хотите отменить подписку, напишите в нашу Службу поддержки. Для доступа к полной версии продукта вам понадобится мобильный телефон Android или iOS. Вы можете сделать снимок экрана с этой информацией и сохранить его."
        },
        payment: {
            title: "выберите способ оплаты",
            start: "Начните ваш план на ",
            or:  "или",
            after: "После успешной оплаты, пожалуйста, проверьте вашу почту.",
            trial: "3-day trial",
            justFor: " just for ",
            trial3: "3-day trial",
            then: " then ",
            afterFirst: {
                after: "После первого периода на ",
                per: " за "
            },
            cancelAny: "Возможность отменить в любое время",
            cardnumber: "Номер карты",
            mmyy: 'MM/YY',
            cardname: "Имя владельца карты",
            email: "E-mail",
            perDay: " в день",
            success: "Оплата прошла успешно!",
            checkEmail: "Пожалуйста, проверьте вашу почту, указанную во время оплаты."
        },
        registration: {
            steps: {
                0: "Покупка\nплана",
                1: "Создание\nаккаунта",
                2: "Live\nClasses",
                3: "Add\noffer",
                4: "Доступ\nк плану",
                stepsWidth: '250px',
                fontSize: '12px'
            },
            liveClasses: {
                title: "Add Live Chair Dance Workouts to your Personal Plan",
                subtitle: "Everdance members who take",
                subtitleBold: "live dance workouts achieve their goals 2x faster",
                continueButton: "Add to my plan"
            },
            addOffer: {
                title: "Exclusive sign-up offer",
                ceoTitle: "Train like a CEO of Everdance app",
                ceoSubtitle: "Get visible results in 4 weeks",
                ceoWorkouts: "12 workouts",
                ceoWarmups: "+2 warm ups",
                ceoAccess: "LIFETIME ACCESS",
                ceoDiscount: "75% OFF",
                continueButton: "Add to my plan",
                skipButton: "Skip this and continue enrollment",
            },
            addOfferList: {
                            title: "Exclusive sign-up offer",
                            continueButton: "Add to my plan",
                            bookAndPlan: "Healthy Tips + Fat-Burning Plan",
                            mealPlan: "🎉 Free bonus MEAL PLAN",
                            book: "Healthy Tips Book form Akilah",
                            plan: "Fat-Burning Home Workout Plan",
                            oneTimePayment: "One-time payment",
                            uniqueTips: "Unique tips from Akilah to get more value from chair workouts",
                            tipsForPlus: "Tips for Plus Size women",
                            specialExercise: "Special exercises to speed up your weight loss",
                            hiit: "Exclusive HIIT and dance workouts from CEO of Everdance app",
                            freeMealPlan: "Free bonus MEAL PLAN"
                        },
            skipLink: "Skip",
            title: "Создайте аккаунт",
            subtitle: "чтобы получить доступ к плану",
            fullname: "Ваше полное имя",
            email: "Ваш email",
            password: "Пароль",
            confirmPassword: "Подтвердите пароль",
            emailOk: "Данный email доступен для регистрации",
            emailExists: "Данный email уже используется",
            emailErr: "Неверное значение email",
            passwordLength: "Пароль должен быть не менее 6 символов",
            passwordMatch: "Пожалуйста, убедитесь что пароль совпадает",
            create: "Создать аккаунт",
            withApple: "Продолжить с Apple",
            withFB: "Продолжить с Facebook",
            withGmail: "Продолжить с Google",
            withEmail: "Продолжить с почтой",
            bySigning: "Регистрируясь в Everdance, вы соглашаетесь с",
            view: "Ознакомьтесь с нашими ",
            created: "Ваш аккаунт успешно создан",
            nextSteps: "Следуйте этим шагам, чтобы получить доступ к своему плану",
            download: "Скачайте приложение Everdance app",
            toPhone: "на ваше устройство",
            use: "Используйте ",
            login: "Log in",
            toLogin: " чтобы авторизоваться",
            youShould: "Вы должны нажать на кнопку Log in и выбрать способ регистрации из предыдущего шага",
            enter: "Выберите метод регистрации в приложение из предыдущего шага.",
        }
    },
    esp: {
            introHeaderVariants: {
                "chair": "Vea qué tan rápido puedes alcanzar tus objetivos de forma física con los entrenamientos en silla",
                "common": "Únate a tu desafío de pérdida de peso bailando",
                "ceo": "Únate al desafío de pérdida de peso de danza y fitness",
                "cfo": "¡Logre tus objetivos de acondicionamiento físico con entrenamientos suaves en silla para personas mayores!"
            },
            introPrefixVariants: {
                "common": "Consigue una nueva tú en 4 semanas",
                "halloween": "Oferta especial de Halloween",
                "cfo": "Conviértete en tu nueva yo en sólo 4 semanas",
                "bf": "🔥Oferta especial de viernes negro🔥",
                "bfb": "OFERTA ESPECIAL DEL VIERNES NEGRO"
            },
            introSubtitleLoadingVariants: {
                "common": "Realiza el cuestionario GRATUITO para obtener tu plan de baile personalizado.",
                "ceo": "Responda el cuestionario GRATUITO para obtener tu plan personalizado de baile y acondicionamiento físico.",

            },
            introSubtitle: "Para comenzar, seleccione tu objetivo principal:",
            introSubtitleLoading: "Realiza el cuestionario GRATUITO para obtener tu plan de baile personalizado.",
            introLoadingText: "Cargando el cuestionario ",
            introAgreements: "Al continuar aceptas",
            introRights: "Reservados todos los derechos.",
            continue: "Continuar",
            getStarted: "EMPEZAR",
            continueTrial1: "Obtén 3 días para $1.00",
            continueMyTrial: "Iniciar mi prueba de 3 días",
            wait: "Espera por favor...",
            recommended: "Recomendada",
            success: "Exitosa",
            images: {
                "about-cf-bf": "/images/result/about-result-decoration-image-cf.png",
                "about-cf-bf-webp": "/images/result/about-result-decoration-image-cf.webp",
                "about": "/images/result/about-result-decoration-image.png",
                "about-webp": "/images/result/about-result-decoration-image.webp",
                "about_bf": "/images/result/about-result-decoration-image_bf.png",
                "about_bg-webp": "/images/result/about-result-decoration-image_bf.webp"
            },
            imgs: {
                "black-friday-icon-ret70": "/images/paywall/black-friday-icon-ret70.png",
                "black-friday-icon-ret70Webp": "/images/paywall/black-friday-icon-ret70.webp",
                "black-friday-icon": "/images/paywall/black-friday-icon.png",
                "black-friday-iconWebp": "/images/paywall/black-friday-icon.webp",
                "bf_icon_disc_75": "/images/paywall/bf_icon_disc_75.png",
                "bf_icon_disc_75Webp": "/images/paywall/bf_icon_disc_75.webp",
                "bf_icon_disc_60": "/images/paywall/bf_icon_disc_60.png",
                "bf_icon_disc_60Webp": "/images/paywall/bf_icon_disc_60.webp",
                "bf_icon_trial": "/images/paywall/bf_icon_trial.png",
                "bf_icon_trialWebp": "/images/paywall/bf_icon_trial.webp"
            },
            intro: {
                backgroundImage: null,
                backgroundImageWebp: null,
                mainGoal:{
                    stayFit: {text: "Mantente en forma", fontSize: '23px'},
                    loseWeight: {text: "Perder peso", fontSize: '23px'},
                    burn: {text: "Quemar calorías", fontSize: '23px'},
                    feel: {text: "Sentirte increíble y segura", fontSize: '23px'},
                    dance: {text: "Baila y diviertete", fontSize: '23px'},
                }
            },
            goal: {
                label: "META",
                goalBanner: {
                    bannerTitle: "1 millón de personas",
                    bannerSubtitle: " han elegido Everdance",
                    points: {
                        first: "Si estás limitada a levantarte ejercicios",
                        second: "Perfecto para personas mayores",
                        third: "Bueno para personas en silla de ruedas",
                        fourth: "Mejor para las embarazadas",
                        fifth: "Mejor para personas con sobrepeso",
                    }
                },
                mainGoal:{
                    title: "¿Cuál es tu principal objetivo?",
                    subtitle: "Nuestro plan se adapta a todo tipo de objetivos.",
                    labels: {
                        stayFit: "Mantener en forma",
                        loseWeight: "Perder peso",
                        burn: "Quemar calorías",
                        feel: "Sentirse increíble y segura",
                        dance: "Bailar y divertirse",
                        gain: "Gain muscel mass",
                        recover: "Recuperarse de una cirugía o una lesión",
                        relievePain: "Aliviar el dolor de las articulaciones",
                        flexibility: "Mejorar la flexibilidad",
                        healthyLife: "Vivir la vida sana",
                        mobility: "Mejorar la movilidad",
                        activeLimited: "Mantenerse activo con movilidad limitada"
                    }
                },
                motivation: {
                    title: "¿Qué es lo que más te motiva?",
                    subtitle: "Esto nos ayudará a encontrar el mejor programa para usted.",
                    labels: {
                        healthy: "Llevar una vida saludable",
                        enjoy: "Divertirse",
                        lookBetter: "Lucir mejor",
                        reduceStress: "Reducir el estrés",
                        improveSE: "Mejorar la autoestima"
                    }
                },
                targetZone: {
                    title: "Elige tu zona objetivo",
                    subtitle: "Nuestro programa de entrenamiento de baile se centrará en esa área.",
                    labels: {
                        arms: "Brazos",
                        chest: "Pecho",
                        belly: "Barriga",
                        hips: "Caderas",
                        legs: "Piernas",
                    }
                },
                currentBody: {
                    multicolorHeader: {
                        titleOne: "Elige tu",
                        titleTwo: " actual",
                        titleThree: " forma del cuerpo",
                    },
                    title: "Elige la forma de tu cuerpo",
                    plump: "Rechoncha",
                    skinny: "Flaca"
                },
                targetBody: {
                    titleStart: " Elige tu",
                    titleEnd: " forma del cuerpo",
                    titleDesired: " deseada",
                    plump: "Rechoncha",
                    skinny: "Flaca",
                    medium: "Medio",
                    curvy: "Con curvas"
                },
                result: {
                    reviewTitle_default: "Gracias por cambiar mi vida",
                    reviewTitle_cfo_ng: "Esto me levanta y me mueve",
                    reviewTitle: "Gracias por cambiar mi vida",
                    default: "¡Chicos, sois increíblemente increíbles! Nunca hubiera pensado que bailar finalmente podría ayudarme a perder peso sin ningún entrenamiento extenuante y dándome un inmenso placer con nuevos movimientos.",
                    cf: "¡Chicos, sois increíblemente increíbles! Nunca hubiera pensado que los ejercicios en silla finalmente podrían ayudarme a perder peso sin ningún entrenamiento agotador y dándome un inmenso placer con los nuevos movimientos.",
                    cfo: "Nunca hubiera pensado que los ejercicios en silla finalmente podrían ayudarme a perder peso sin ningún entrenamiento agotador y dándome un inmenso placer con nuevos movimientos.",
                    cfo_ng: "¡¡¡Bailó la silla para principiantes por primera vez con el entrenador Akilah y fue GENIAL!!! ¡La maestra fue excepcional! as Seleccioné la silla porque la semana pasada me empezó a doler la rodilla sin razón aparente🥺.",
                    reviewAuthor: "Ashley Townsend"
                }
            },
            about: {
                label: "ACERCA DE TI",
                age: {
                    title: "¿Cuántos años tienes?",
                    subtitle: "Esto nos ayudará a realizar ajustes a tu plan personal.",
                    age: "EDAD"
                },
                height: {
                    title: "Tu altura",
                    subtitle: "Esto nos permitirá definir tu índice de masa corporal y adaptar tu plan de entrenamiento.",
                    ft: "FT",
                    in: "IN",
                    cm: "CM",
                    errorGreater: "El valor debe ser mayor o igual a",
                    errorLess: "El valor debe ser menor o igual a "
                },
                currentWeight: {
                    title: "Tu peso actual",
                    subtitle: "Esto nos permitirá definir tu índice de masa corporal y adaptar tu plan de entrenamiento.",
                    lbs: "LBS",
                    kg: "KG"
                },
                goalWeight: {
                    title: "Tu peso objetivo",
                    subtitle: "Esto nos permitirá definir tu índice de masa corporal y adaptar tu plan de entrenamiento.",
                    lbs: "LBS",
                    kg: "KG"
                },
                errors: {
                    numeric: "Sólo valor numérico",
                    errorGreater: "El valor debe ser mayor o igual a",
                    errorLess: "El valor debe ser menor o igual a",
                    errorCurrent: "El valor debe ser menor que el peso actual que ingresó.",
                },
                result: {
                    title: "Mujeres en su ",
                    subtitleStart: "¿Quién apunta al peso? ",
                    subtitleEnd: " Recuerde: el cuidado y la atención deben convertirse en una parte integral de su entrenamiento diario. ¡No se olvide de la salud y armonía de su cuerpo!",
                    subtitleColor: {
                        first: "que quieren perder peso,",
                        second: "Los entrenamientos en silla pueden cambiar las reglas del juego.",
                        start: "No",
                        red: " restricciones ",
                        and: "y",
                        blue: " ejercicios ",
                        end: "agotadores.",
                        last: "Pierde peso desde la comodidad de tu hogar.",
                        lastTrain: "Train from the comfort of your home...",
                        bold: "¡La solución perfecta está aquí!"
                    }
                }
            },
            lifestyle: {
                label: "ESTILO DE VIDA",
                activity: {
                    title: "¿Cuál es tu nivel de actividad?",
                    subtitle: "Tu programa se ajustará a tu estilo de vida actual.",
                    levels: {
                        sitting: {
                            title: "Sesión",
                            description: "No haces ejercicio formal y no realizas actividad física durante el día."
                        },
                        sedentary: {
                            title: "Sedentaria",
                            description: "No haces ejercicio formal y no realizas actividad física durante el día."
                        },
                        lightly: {
                            title: "Ligeramente activa",
                            description: "Realizas actividades de estilo de vida, como sacar al perro a pasear o hacer jardinería."
                        },
                        moderate: {
                            title: "Moderadamente activa",
                            description: "Participas en ejercicios cardiovasculares durante 20 a 60 minutos, de 3 a 5 días por semana."
                        },
                        high: {
                            title: "Altamente activa",
                            description: "Haces ejercicio de 20 a 60 minutos la mayoría de los días de la semana."
                        }
                    }
                },
                busy: {
                    title: "¿Qué tan ocupada estás en un día promedio?",
                    subtitle: "Esto nos ayudará a encontrar el mejor programa para ti.",
                    labels: {
                        barelyHaveTime: "Apenas tengo tiempo para mí",
                        busy: "Estoy ocupada, pero tengo tiempo libre",
                        notBusy: "No estoy muy ocupada",
                        flexible: "Mi horario es flexible"
                    }
                },
                children: {
                    title: "¿Tienes hijos?",
                    subtitle: "Esto nos ayudará a encontrar el mejor programa para ti.",
                    labels: {
                        yes:  {
                            text: "Sí",
                            subtext: "¡¡¡Excelente!!! El baile es una forma perfecta de mejorar la relación con tu hijo"
                        },
                        no: "No",
                        grandkids: "Hombre, ya tengo nietos"
                    }
                },
                diet: {
                    title: "¿Cómo describirías tu dieta?",
                    subtitle: "Esto nos permitirá preparar recomendaciones útiles para ti.",
                    labels: {
                        healthy: {
                            text: "Saludable",
                            subtext: "Como un poco de todo y no demasiada comida chatarra."
                        },
                        not_good: {
                            text: "Podria ser mejor",
                            subtext: "Tiendo a sentirme atraído por la comida grasosa y no como muchas verduras."
                        },
                        poor: {
                            text: "Mala alimentación",
                            subtext: "Como principalmente alimentos grasosos y muy pocas verduras."
                        }
                    }
                },
                result: {
                    title: "Cumplir con un plan puede ser difícil. Everdance lo hace fácil.",
                    comment: "“Everdance hizo del baile mi rutina diaria y me ahorró tiempo para ir a hacer ejercicio. Ahora puedo entrenar desde casa y priorizar el autocuidado.“",
                    cfCommentFirst: "“Everdance hizo excelentes ejercicios en silla“.",
                    cfCommentLast: "Actualmente estoy tratando de perder algo de peso antes de mi cirugía de reemplazo de rodilla y estos son excelentes ejercicios de bajo impacto“.",
                    commentAuthor: "- Ashley",
                    commentAuthorM: "- Mike",
                    commentHint: {
                        start: "* Las usuarias de Everdance reportan cambios positivos.",
                        middle: " después de 1 semana ",
                        end: "de uso."
                    }
                }
            },
            nutrition: {
                label: "NUTRICIÓN",
                breakfast: {
                    title: "¿Cuándo sueles desayunar?",
                    sixEight: {
                        text: "Entre las 6 y las 8 am", fontSize: '16px'
                    },
                    eightTen: {
                        text: "Entre 8 y 10 am", fontSize: '16px'
                    },
                    tenNoon: {
                        text: "Entre las 10 y el mediodía", fontSize: '16px'
                    },
                    skip: {
                        text: "Normalmente me salto el desayuno", fontSize: '16px'
                    }
                },
                lunch: {
                    title: "¿Qué tal el almuerzo?",
                    tenNoon: {
                        text: "Entre las 10 y el mediodía", fontSize: '16px'
                    },
                    noonTwo: {
                        text: "Entre el mediodía y las 2 de la tarde", fontSize: '16px'
                    },
                    twoFour: {
                        text: "Entre las 14 y las 16 horas", fontSize: '16px'
                    },
                    skip: {
                        text: "Normalmente me salto el almuerzo", fontSize: '16px'
                    }
                },
                dinner: {
                    title: "¿A qué hora cenas?",
                    fourSix: {
                        text: "Entre las 4 y las 6 de la tarde", fontSize: '16px'
                    },
                    sixEight: {
                        text: "Entre las 6 y las 8 pm", fontSize: '16px'
                    },
                    eightTen: {
                        text: "Entre las 8 y las 10 pm", fontSize: '16px'
                    },
                    skip: {
                        text: "Normalmente me salto la cena", fontSize: '16px'
                    }
                },
                typeOfDiet: {
                    title: "¿Qué tipo de dieta prefieres?",
                    withMeat: "CON CARNE",
                    withoutMeat: "SIN CARNE",
                    withFish: "CON PESCADO",
                    withoutAllergens: "SIN ALÉRGENOS",
                    labels: {
                        withMeatTraditional: {
                            text: "Tradicional",
                            subtext: "disfruto todo"
                        },
                        withMeatKeto: {
                            text: "cetogénico",
                            subtext: "Prefiero comidas altas en grasas y bajas en carbohidratos."
                        },
                        withMeatPaleo: {
                            text: "Paleo",
                            subtext: "No como alimentos procesados"
                        },
                        withoutMeatVegetarian: {
                            text: "Vegetariana",
                            subtext: "evito la carne y el pescado"
                        },
                        withoutMeatVegan: {
                            text: "Vegano (dieta vegetal)",
                            subtext: "No como productos animales"
                        },
                        withoutMeatKetoVegan: {
                            text: "Ceto vegana",
                            subtext: "Sólo como comidas bajas en carbohidratos a base de plantas"
                        },
                        withFishMediterranean: {
                            text: "Mediterránea",
                            subtext: "Como muchas verduras, cereales y mariscos."
                        },
                        withFishMescatarian: {
                            text: "pescatariano",
                            subtext: "Evito la carne pero disfruto el pescado."
                        },
                        withoutAllergensLactoseFree: {
                            text: "Sin lactosa",
                            subtext: "No consumo alimentos con lactosa."
                        },
                        withoutAllergensGlutenFree: {
                            text: "Sin gluten",
                            subtext: "evito los cereales que contienen gluten"
                        }
                    }
                },
                badHabits: {
                    title: "¿Tienes alguno de los siguientes malos hábitos?",
                    subtitle: "Elije todo lo que corresponda",
                    labels: {
                        emotional: "Comer emocionalmente o por aburrimiento",
                        overeating: "Comer en exceso",
                        snacking: "Meriendas nocturnas",
                        skipping: "Saltarse comidas con demasiada frecuencia",
                        none: "Ninguno de los anteriores"
                    }
                },
                craveFoods: {
                    title: "¿Qué alimentos se te antojan con más frecuencia?",
                    subtitle: "Elije todo lo que corresponda",
                    labels: {
                        sweet: "Dulces",
                        salty: "Bocadillos salados",
                        fast: "Comida rápida",
                        soda: "Soda",
                        none: "Ninguno de los anteriores"
                    }
                },
                nutritionResult: {
                    title: "Recordatorio",
                    comment: "“El 80% de tus resultados proviene de los alimentos que consumes y sólo el 20% de tus resultados provienen de tus entrenamientos“.",
                    note: "*Los resultados no están garantizados. Las personas que usan Everdance pueden esperar perder entre 1 y 2 libras por semana.",
                    feedbacks: {
                        genia: {
                            text: "“He perdido 9 kg hasta la fecha y creo que Everdance cambiará mi vida para siempre“.",
                            name: "- Genia"
                        },
                        sonya: {
                            text: "“Gracias a Everdance he tonificado mi cuerpo y cambiado mis hábitos.”",
                            name: "- Sonya"
                        },
                        zhang: {
                            text: "“He aumentado mi energía a un nuevo nivel con buenas comidas y ejercicios de baile regulares”.",
                            name: "- Zhang"
                        },
                    }
                }
            },
            plan: {
                label: "TU PLAN",
                heardAbout: {
                    title: "¿Has oído hablar antes de los entrenamientos de baile en silla?",
                    labels: {
                        regularly: "Si las practico regularmente",
                        tried: "Sí, lo he intentado",
                        neverTried: "No, nunca las he probado"
                    }
                },
                chairWorkout: {
                    title: "¿Qué son los entrenamientos en silla?",
                    comment: {
                        text: "“Realizar ejercicios en silla puede ayudar a las articulaciones y quemar calorías. Son más beneficiosos para personas con problemas de movilidad”.",
                        senior: "Perfecto para personas mayores",
                        subtext: "Perfecto para mujeres en",
                        last: "s"
                    }
                },
                benefits: {
                    title: "Beneficios de los entrenamientos de baile en silla.",
                    labels: {
                        weightLoss: "Pérdida de peso",
                        lowerRisk: "Menor riesgo de diabetes tipo 2",
                        betterHeart: "Mejor salud del corazón",
                        cancerPrevention: "Prevención del cáncer",
                        longevity: "Longevidad",
                        reducedJointPain: "Reducción del dolor y la rigidez de las articulaciones",
                        betterBloodCirculation: "Mejor circulación sanguínea"
                    }
                },
                usersLove: {
                    title: "A los usuarios les encantan nuestros entrenamientos en silla",
                },
                experience: {
                    title: "¿Qué describiría mejor tu experiencia de baile?",
                    subtitle: "Esto nos ayudará a mejorar su experiencia de usuario en Everdance.",
                    labels: {
                        beginner: {
                            text: "Principiante",
                            subtext: "Recién comenzando"
                        },
                        intermediate: {
                            text: "Intermedia",
                            subtext: "yo bailo de vez en cuando"
                        },
                        advanced: {
                            text: "Avanzada",
                            subtext: "tomo clases de baile"
                        }
                    }
                },
                styles: {
                    title: "¿En qué estilos de baile quieres trabajar?",
                    subtitle: "Esto ayudará a tener en cuenta tus preferencias de estilos al hacer recomendaciones.",
                    labels: {
                        hiphop: "Hip-Hop",
                        kpop: "K-Pop",
                        dancehall: "Dancehall",
                        latino: "Solo Latino",
                        belly: "Belly Dance",
                        tiktok: "Tik-Tok",
                        vogue: "Vogue",
                        twerk: "Twerk",
                        heels: "Heels",
                        jazzfunk: "Jazz-funk"
                    },
                    donotknow: "No conozco estos estilos"
                },
                times: {
                    title: {
                        dance: "¿Cuántas veces a la semana te gustaría bailar?",
                        workOut: "¿Cuántas veces por semana te gustaría hacer ejercicio?",
                    },
                    labels: {
                        0: "1-2",
                        1: "3-4",
                        2: "5 o más"
                    }

                },
                session: {
                    title:{
                        dance: "¿Cuánto tiempo te gustaría que dure tu sesión de baile?",
                        workOut: "¿Cuánto tiempo te gustaría que dure la sesión de ejercicio?",
                    },
                    labels: {
                        0: "10-15 minutos",
                        1: "20-30 minutos",
                        2: "35-45 minutos",
                        3: "5-10 minutos",
                        4: "15-30 minutos"
                    }
                },
                danceExercise:{
                    title: "Danza vs ejercicios regulares",
                    subtitle: "Promedio de calorías quemadas en 30 min.",
                    continue: "Continuar",
                    info: "“Si miramos las tiras del pulsómetro de la sesión de Zumba fitness… quemas calorías extra en comparación con un ejercicio de estado estable”"
                },
                chartBanner:{
                    title: "¡Tu plan de adelgazamiento de 4 semanas bailando está listo!",
                    subtitle: "*basado en la información de los usuarios que registran su progreso en la aplicación",
                    continue: "Continuar",
                    infoBox: {
                        percent: "65%",
                        start: " De usuarias que comenzaron su ",
                        info: "Plan de adelgazamiento bailando con Everdance",
                        cfInfo: "Plan de entrenamiento en silla con Everdance",
                        middle: " avanzado en sus objetivos dentro del ",
                        last: "primer mes*"
                    },
                },
                creatingPlan: {
                    title: "La tecnología más innovadora del mercado.",
                    subtitle: "Creando tu plan personalizado...",
                    messages: [
                        {
                            firstTitle: "Personal",
                            secondTitle: " plan de baile ",
                            thirdTitle: "según tus objetivos"
                        },
                        {
                            firstTitle: "Sigue tu progreso y",
                            secondTitle: " obtener logros",
                            thirdTitle: ""
                        },
                        {
                            firstTitle: "Calorias quemadas",
                            secondTitle: " AI ",
                            thirdTitle: "rastreador"
                        }
                    ]
                },
                resultProgress: {
                    title: "¡Te entendemos! Adaptemos tu programa de ejercicios a tu perfil de condición física",
                },
                result: {
                    title: "1 millón de personas",
                    subtitle: "Con la confianza de más de 1.423.658 usuarios",
                    processTitle: "Creando tu plan...",
                    labels: {
                        25: "Escaneando tus objetivos",
                        50: "Analizando parámetros corporales",
                        75: "Elegindo clases según tus necesidades",
                        100: "Generando tu plan de acción"
                    },
                    comments: {
                        0: {
                            title: "¡Asombrosa!",
                            subtitle: "He perdido 9 kg hasta la fecha y creo que Everdance cambiará mi vida para siempre.",
                            author: "Genia Johansen",
                        },
                        1: {
                            title: "Me encanta esta aplicación",
                            subtitle: "Gracias a Everdance he tonificado mi cuerpo y cambiado mis hábitos",
                            author: "Olivia Emma",
                        },
                        2: {
                            title: "Excelente aplicación",
                            subtitle: "He aumentado mi energía a un nuevo nivel con buenas comidas y ejercicios de baile regulares.",
                            author: "Ummu Yilmaz"
                        },
                        3: {
                            title: "¡Asombroso!",
                            subtitle: "Realmente disfruté este entrenamiento. Fue muy divertido y realmente me metí en ello y en los movimientos. No puedo creer que esté sudando un poco. Fue muy divertido. Voy a disfrutar haciendo ejercicio en mi silla.",
                            author: "Genia Johansen",
                        },
                        4: {
                            title: "Me encanta esta aplicación",
                            subtitle: "Esto ayudará a muchos. Porque algunas personas luchan debido a las lesiones. O obesidad severa. ¡¡¡Ellos pueden hacer esto!!!!...",
                            author: "Olivia Emma",
                        }
                    }
                }
            },
            paywall: {
                greenTheme: {
                    beforeTitle: "Puedes alcanzar tu meta",
                    title: "con Plan de Baile Personal",
                    cfTitle: "con Plan Personal de Baile en Silla",
                    chartNotice: "*Este cuadro es sólo para fines ilustrativos.",
                    specialTitle: {
                        title: {
                            bf: "VIERNES NEGRO"
                        },
                        preview: {
                            specialOffer: "OFERTA ESPECIAL"
                        },
                        upText: {
                            to_50: "HASTA 50% DE DESCUENTO",
                            to_60: "HASTA 60% DE DESCUENTO"
                        }
                    },
                    mealPlan: {
                        titleOne: "Impulso de pérdida de peso",
                        titleTwo: "Acelera tus resultados con nuestro",
                        titleThree: "Plan de alimentación y programa de entrenamientos HIIT",
                        timer: "EXPIRA EN:",
                        stickyTimer: "La oferta de mejora de bonificación gratuita vence en",
                    },
                    cards: {
                        cfTitleOne: "Agarra el plan de baile en silla personal",
                        titleOne: "Coga tu plan de baile personal",
                        titleTwo: "+ Impulso de pérdida de peso",
                        titleThree: "¡antes de que se acabe!",
                        titleRecovery: "+ Impulso de recuperación",
                        notifyBox: {
                            mainText: "Las personas que usan Everdance durante 3 meses pierden el doble de peso que durante 1 mes*",
                            subText: "*Según la investigación de usuarios de Everdance, 2023",
                            notifyText: "*Puedes cancelar en cualquier momento. Todos los precios están en USD."
                        }
                    },
                    downloadApps: {
                        title: "1,4+ millones",
                        subtitle: "Los usuarios comenzaron su viaje de pérdida de peso con nosotros.",
                    },
                    stayOnTop: {
                        titleOne: "Mantente al tanto de tu viaje de baile con ",
                        titleTwo: "la aplicación Everdance",
                    }
                },
                reserved: "precio reservado para:",
                title: {
                    start: "Tu",
                    personal: "personalizada",
                    end: " El programa de 30 días está listo"
                },
                products: {
                    "1-month plan": "plan de 1 mes",
                    "3-month plan": "plan de 3 meses ",
                    "1-week plan": "plan de 1 semana",
                    "6-month plan": "plan de 6 meses",
                    "1-WEEK TRIAL": "1-SEMANA PRUEBA",
                    "4-WEEK PLAN": "4-SEMANA PLAN",
                    "12-WEEK PLAN": "12-SEMANA PLAN",
                    "24-WEEK PLAN": "24-SEMANA PLAN"
                },
                periods: {
                    "3 months": "3 meses ",
                    "week": "semana ",
                    "1 month": "1 mes ",
                    "6 months": "6 meses ",
                    "quarterly": "trimestral",
                    "half a year": "por medio año",
                    "per month": "por mes",
                    "weekly": "por semana",
                    "1 week trial": "1 semana prueba ",
                    "12 weeks": "12 semanas ",
                    "24 weeks": "24 semanas ",
                    "4 weeks": "4 semanas ",
                    "per 4 week": "por 4 semanas ",
                    "per 12 week": "por 12 semanas ",
                    "per 24 week": "por 24 semanas ",
                },
                goal: "Meta",
                targetWeight: "Peso objetivo",
                cancelAnytime: "*Puedes cancelar en cualquier momento. Todos los precios están en USD.",
                peopleAsk: "La gente pregunta a menudo",
                love: "A los usuarios les encantan nuestros planes.",
                get: "lo que obtienes",
                features: {
                    0: {
                        p1: "Acceso completo a",
                        p2: "350+ baile ",
                        p3: "clases"
                    },

                    1: {
                        p1: "Personal ",
                        p2:  "bailar",
                        p3:  "plan"
                    },
                    2: "Premio especial para nuestros suscriptores",
                    3: "Clases de baile en vivo 2 veces por semana.",
                    4: "Consejos y trucos útiles diarios",
                    5: {
                        p1: "Comentarios de",
                        p2: " instructores de baile"
                    },

                    6: {
                        p1: "Sólo 15 minutos por ",
                        p2: "dia para ver",
                        p3: " resultados notables"
                    },
                    7: "Clases de Nike Dancers y Lizzo's Grrrls"
                },
                bonus: {
                    title: "+ BONO GRATIS:",
                    mealPlan: "Plan de comidas y",
                    hiit: "Programa de entrenamientos HIIT"
                },
                refund: {
                    value: "Nuestras usuarias nos valoran:",
                    rate: " tasa de reembolso inferior al 2%*",
                    year: "*basado en datos de 2022",
                    days: "30 días",
                    mbg: "garantía de devolución de dinero"
                },
                discount: {
                    previous: "Descuento anterior: ",
                    titleGet: "Consigue tu plan personal con hasta",
                    titleStart: "Empieza tu plan personal con",
                    discount:  "descuento"
                },
                trial: {
                     titleStart: "Comienza tu plan personal con",
                      trial:  " prueba de 3 días"
                },
                cardHeader: {
                    title: {
                        specialOffer: "OFERTA ESPECIAL",
                        bfOffer: "OFERTA VIERNES NEGRO",
                        nyOffer: "OFERTA DE AÑO NUEVO"
                    },
                    imageTitle: {
                        off50: {text: "50% DE DESCUENTO", fontSize: "16px"},
                        off60: {text: "60% DE DESCUENTO", fontSize: "16px"},
                        trial: {text: "PRUEBA DE 3 DÍAS", fontSize: "16px"},
                        off70: {text: "70% DE DESCUENTO", fontSize: "16px"},
                        off75: {text: "75% DE DESCUENTO", fontSize: "16px"},
                        bf: {text: "VIERNES NEGRO", fontSize: "16px"},
                        ny: {text: "AÑO NUEVO", fontSize: "16px"}
                    },
                    save: {text: "AHORRAR ", fontSize: "12px"},
                    trial: {text: "PRUEBA DE 3 DÍAS", fontSize: "10px"},
                    trial3: {text: "PRUEBA DE 3 DÍAS", fontSize: "10px"},
                    sale: {text: "VENTA", fontSize: "16px"},
                    mostPopular: {text: "MÁS POPULAR", fontSize: "12px"},
                    perDay: "por día"
                },

                questions: {
                    0: {
                        title: "¿Puedo adelgazar bailando?",
                        subtitle: "¡Puedes apostar! Bailar es un excelente ejercicio cardiovascular que no solo te ayuda a quemar calorías, sino que también te ayuda a sentirte más feliz. ¡Pierde peso y siéntete bien haciéndolo!"
                    },
                    1: {
                        title:"¿Cómo puede beneficiarme el baile?",
                        subtitle: "Bailar puede ayudarte a perder el exceso de peso, tonificar los músculos, hacerte más flexible y mejorar la salud cardiovascular. ¡Sin mencionar que te hará sentir más feliz!"
                    },

                    2: {
                        title: "¿Puedo conseguir un cuerpo tonificado bailando?",
                        subtitle: "¡El cuerpo de una bailarina existe por una razón! Al bailar con regularidad, puedes mejorar tu tono muscular, deshacerte de los kilos de más y mejorar tu autoestima"
                    },
                    3: {
                        title: "¿Por qué bailar es un buen ejercicio?",
                        subtitle: "Bailar es increíble porque es adecuado tanto para profesionales como para principiantes en el mundo del deporte. No requiere absolutamente nada para comenzar y puede brindarte mejoras reales"
                    }
                },
                comments: {
                    0: {
                        title: "¡Asombrosa!",
                        subtitle: "Nunca pensé que entrenar podría ser tan divertido y que podría perder tanto peso con una aplicación. Se lo dije a mi mamá y ahora entrenamos juntas y nos encanta.",
                        author: "Emma Johansen",
                        date: "21 de enero"
                    },
                    1: {
                        title: "Me encanta esta aplicación",
                        subtitle: "Chicos, mucho amor y respeto por lo que hacen! ¡Realmente ayuda a perder peso y divertirse al mismo tiempo! Sigan con el buen trabajo.",
                        author: "Xavier",
                        date: "5 de noviembre"
                    },
                    2: {
                        title: "Muchas gracias",
                        subtitle: "Finalmente encontré una aplicación que me mantiene en forma y no me permite aburrirme. He tenido sobrepeso durante tanto tiempo y lo intenté y fracasé tantas veces que casi me di por vencido por completo. Pero estoy realmente sorprendido por Esta aplicación Jajaja, he quemado tantas calorías con esta aplicación simplemente bailando, ¡me encanta!",
                        author: "Jaiah",
                        date: "11 de octubre"
                    }
                },
                feedbacks: {
                    0: {
                        name: "Raquel",
                        before: "27 January, 2023",
                        after: "22 May, 2023",
                        weight: "- 22kg (48 Ib)",
                        feedback: "My story is a common one. I used to eat my stress away and ended up having to completely change my wardrobe. I hated my new self, my big belly, and I was angry. I tried new diets and going to the gym, but I kept falling off track. It wasn't until I started therapy with a psychologist who helped me realize the root of my stress and remember my childhood dreams. That's when I started dancing again. My dance journey is still ongoing, but I can already see the results and it inspires me even more! Plus, now I can never make an excuse that I don't have time to work out because with a mobile dance app, I can do it anywhere and choose dance styles that really excite me."
                    },
                    1: {
                        name: "Anne",
                        before: "1 May, 2023",
                        after: "15 August, 2023",
                        weight: "- 17kg (37 Ib)",
                        feedback: "I'm thrilled that I'm able to get back to my pre-baby shape so easily and enjoyably thanks to dance workouts. Fellow moms will understand how hard it is to find time for self-care when you have a screaming newborn and every day feels like Groundhog Day. But for the past few months, I've been dancing at home and it's been such a joy! Learning new dance moves, seeing my body become more flexible and toned, and watching my belly shrink. And most importantly, it's been improving my mood and rediscovering what my body is capable of!"
                    },
                    2: {
                        name: "Emma",
                        before: "5 April, 2023",
                        after: "23 June, 2023",
                        weight: "- 13kg (33 Ib)",
                        feedback: "I've always had a slim figure and could easily eat whatever I wanted without gaining weight. But that made me too relaxed about my habits. Over time, I started gaining weight without even realizing it, became too lethargic with no energy. That was until my doctor shocked me by saying that I'd develop diabetes if I didn't change my habits, add more physical activity.\nMy friend suggested trying an individual workout plan from a new dance app and working out at home whenever it's convenient for me. Within a couple of weeks, I started noticing my usual energy returning, my body becoming more toned, and I began smiling more."
                    }
                },
                featured: "Como aparece en:",
                usersMeetGoals: "Nuestras usuarias cumplen sus objetivos ",
                peopleLikeYou: "Personas como usted lograron excelentes resultados utilizando nuestro ",
                plan: "Plan de adelgazamiento bailando",
                disclaimer: "Descargo de responsabilidad:",
                key: " Seguir el ejercicio y un plan de alimentación es la clave en tu viaje de acondicionamiento físico y en gran medida los resultados. No es habitual perder más de 4 kg al mes. Consulta primero a un médico.",
                guarantee: "Garantía de devolución de dinero de 30 días",
                results: "¡Creemos que nuestro plan puede funcionar para ti y obtendrá resultados visibles en 4 semanas!",
                return: "Incluso estamos dispuestos a devolverte tu dinero si no ves resultados visibles y puedes demostrar que seguiste nuestro plan.",
                note: "Tenga en cuenta:",
                subscrRenew: " Las suscripciones se renuevan automáticamente al final de cada período a menos que lo canceles. Si deseas cancelar una suscripción, escribe a nuestro Soporte. Se requiere pago anticipado del costo total del plan. Necesitarás un teléfono móvil con Android o iOS para acceder a la versión completa del producto. Es posible que desees tomar una captura de pantalla de esta información y guardarla"
            },
            payment: {
                title: "ELIGE FORMA DE PAGO",
                checkout: "Verificar",
                personalized: "Plan de entrenamiento personalizado",
                offer: "Descuento de oferta de lanzamiento",
                youSave: "Ahorras ",
                total: "Total",
                start: "Comienza tu ",
                or:  "o",
                after: "Después del pago exitoso, verifica tu correo electrónico.",
                trial: "prueba de 3 días",
                trial3: "prueba de 3 días",
                justFor: " solo para ",
                then: " entonces ",
                afterFirst: {
                    after: "después del primero ",
                    per: " por "
                },
                cancelAny: "Cancelar en cualquier momento",
                cardnumber: "Número de tarjeta",
                mmyy: 'MM/AA',
                cardname: "Nombre del titular de la tarjeta",
                email: "Correo electrónico",
                perDay: " por dia",
                success: "¡El pago fue exitoso!",
                checkEmail: "Por favor verifica tu dirección de correo electrónico especificada durante el pago."
            },
            registration: {
                steps: {
                    0: "Comprar\nplan",
                    1: "Crear\ncuenta",
                    2: "Clases\nen vivo",
                    3: "Añadir\nooferta",
                    4: "Acceder\nplan",
                    stepsWidth: '200px',
                    fontSize: '14px'
                },
                liveClasses: {
                    title: "Agregaa entrenamientos de baile en silla en vivo a tu plan personal",
                    subtitle: "Miembros de Everdance que toman",
                    subtitleBold: "Los entrenamientos de baile en vivo logran sus objetivos 2 veces más rápido.",
                    continueButton: "Agregar a mi plan"
                },
                addOffer: {
                    title: "Oferta exclusiva de registro",
                    ceoTitle: "Entrena como un CEO de la aplicación Everdance",
                    ceoSubtitle: "Obten resultados visibles en 4 semanas",
                    ceoWorkouts: "12 entrenamientos",
                    ceoWarmups: "+2 calentamientos",
                    ceoAccess: "ACCESO DE POR VIDA",
                    ceoDiscount: "75% DE DESCUENTO",
                    continueButton: "Añadir a mi plan",
                    skipButton: "Omita esto y continúe con la inscripción",
                },
                addOfferList: {
                                title: "Oferta exclusiva de registro",
                                continueButton: "Agregar a mi plan",
                                bookAndPlan: "Consejos Saludables + Plan Quema Grasas",
                                mealPlan: "🎉 Bono gratis PLAN DE COMIDAS",
                                book: "Libro de consejos saludables de Akilah",
                                plan: "Plan de entrenamiento en casa para quemar grasa",
                                oneTimePayment: "Pago único",
                                uniqueTips: "Consejos únicos de Akilah para sacar más provecho de los entrenamientos en silla",
                                tipsForPlus: "Consejos para mujeres de talla grande",
                                specialExercise: "Ejercicios especiales para acelerar tu pérdida de peso.",
                                hiit: "Entrenamientos exclusivos de HIIT y baile del CEO de la aplicación Everdance",
                                freeMealPlan: "Bono gratis PLAN DE COMIDAS"
                            },
                skipLink: "Saltar",
                title: "Regístrate en Everdance",
                subtitle: "para acceder al plan",
                fullname: "Nombre completo",
                email: "Tu correo electrónico",
                password: "Contraseña",
                confirmPassword: "Confirmar Contraseña",
                emailOk: "Correo electrónico actual disponible para registrarse",
                emailExists: "El correo electrónico actual ya existe",
                emailErr: "Valor de correo electrónico incorrecto",
                passwordLength: "La contraseña debe tener al menos 6 caracteres",
                passwordMatch: "Por favor asegúrate de que tus contraseñas coincidan",
                create: "CREAR UNA CUENTA",
                withApple: "Continuar con Apple",
                withFB: "Continuar con Facebook",
                withGmail: "Continuar con Google",
                withEmail: "Continuar con el correo electrónico",
                bySigning: "Al suscribirse a Everdance, tu aceptas la",
                view: "Ver nuestra",
                created: "Tu cuenta fue creada",
                nextSteps: "Siga estos pasos para obtener acceso a tu plan",
                download: "Descarga la aplicación Everdance",
                toPhone: "a tu teléfono",
                use: "Usar",
                login: "Acceso",
                toLogin: " para iniciar sesión",
                youShould: "Debes tocar para Iniciar sesión y elegir el método de registro del paso anterior.",
                enter: "Elige el método para iniciar sesión del paso anterior ",
            }

        }

});

export default strings;